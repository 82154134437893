





// import React, { useState, useRef, useEffect } from 'react';
// import Picker from 'emoji-picker-react';
// import './frameNew.css';


// import chatBoxImage from '../../../static/img/chatBoxImageCrop1.png'
// import arrowDown from '../../../static/img/arrowDownConvert.png'

// import chatSoundIconWhite from '../../../static/img/chatSoundSpeaker.png'
// import chatSpeakerMuteIcon from '../../../static/img/chatMuteSpeaker.png'
// import { useFetcher, useLocation, useParams } from 'react-router-dom';



// const MessageInput = () => {


//   // const avatarList = [
//   //   { name: 'Shweta', image: '/img/avatar/woman.png' },
//   //   { name: 'Jensey', image: '/img/avatar/avatarAman.svg' },
//   //   { name: 'Aman', image: '/img/avatar/man.png' },
//   //   { name: 'Salman', image: '/img/avatar/avatarAman.svg' },
//   //   { name: 'Alex', image: '/img/avatar/man.png' },
//   //   { name: 'Dharmesh', image: '/img/avatar/woman.png' },
//   // ];



//   const languages = [
//     {
//       "language": "Afrikaans",
//       "code": "af"
//     },
//     {
//       "language": "Albanian",
//       "code": "sq"
//     },
//     {
//       "language": "Amharic",
//       "code": "am"
//     },
//     {
//       "language": "Arabic",
//       "code": "ar"
//     },
//     {
//       "language": "Armenian",
//       "code": "hy"
//     },
//     {
//       "language": "Azerbaijani",
//       "code": "az"
//     },
//     {
//       "language": "Basque",
//       "code": "eu"
//     },
//     {
//       "language": "Belarusian",
//       "code": "be"
//     },
//     {
//       "language": "Bengali",
//       "code": "bn"
//     },
//     {
//       "language": "Bosnian",
//       "code": "bs"
//     },
//     {
//       "language": "Bulgarian",
//       "code": "bg"
//     },
//     {
//       "language": "Catalan",
//       "code": "ca"
//     },
//     {
//       "language": "Cebuano",
//       "code": "ceb"
//     },
//     {
//       "language": "Chinese (Simplified)",
//       "code": "zh-CN"
//     },
//     {
//       "language": "Chinese (Traditional)",
//       "code": "zh-TW"
//     },
//     {
//       "language": "Corsican",
//       "code": "co"
//     },
//     {
//       "language": "Croatian",
//       "code": "hr"
//     },
//     {
//       "language": "Czech",
//       "code": "cs"
//     },
//     {
//       "language": "Danish",
//       "code": "da"
//     },
//     {
//       "language": "Dutch",
//       "code": "nl"
//     },
//     {
//       "language": "English",
//       "code": "en"
//     },
//     {
//       "language": "Esperanto",
//       "code": "eo"
//     },
//     {
//       "language": "Estonian",
//       "code": "et"
//     },
//     {
//       "language": "Finnish",
//       "code": "fi"
//     },
//     {
//       "language": "French",
//       "code": "fr"
//     },
//     {
//       "language": "Frisian",
//       "code": "fy"
//     },
//     {
//       "language": "Galician",
//       "code": "gl"
//     },
//     {
//       "language": "Georgian",
//       "code": "ka"
//     },
//     {
//       "language": "German",
//       "code": "de"
//     },
//     {
//       "language": "Greek",
//       "code": "el"
//     },
//     {
//       "language": "Gujarati",
//       "code": "gu"
//     },
//     {
//       "language": "Haitian Creole",
//       "code": "ht"
//     },
//     {
//       "language": "Hausa",
//       "code": "ha"
//     },
//     {
//       "language": "Hawaiian",
//       "code": "haw"
//     },
//     {
//       "language": "Hebrew",
//       "code": "iw"
//     },
//     {
//       "language": "Hindi",
//       "code": "hi"
//     },
//     {
//       "language": "Hmong",
//       "code": "hmn"
//     },
//     {
//       "language": "Hungarian",
//       "code": "hu"
//     },
//     {
//       "language": "Icelandic",
//       "code": "is"
//     },
//     {
//       "language": "Igbo",
//       "code": "ig"
//     },
//     {
//       "language": "Indonesian",
//       "code": "id"
//     },
//     {
//       "language": "Irish",
//       "code": "ga"
//     },
//     {
//       "language": "Italian",
//       "code": "it"
//     },
//     {
//       "language": "Japanese",
//       "code": "ja"
//     },
//     {
//       "language": "Javanese",
//       "code": "jw"
//     },
//     {
//       "language": "Kannada",
//       "code": "kn"
//     },
//     {
//       "language": "Kazakh",
//       "code": "kk"
//     },
//     {
//       "language": "Khmer",
//       "code": "km"
//     },
//     {
//       "language": "Korean",
//       "code": "ko"
//     },
//     {
//       "language": "Kurdish",
//       "code": "ku"
//     },
//     {
//       "language": "Kyrgyz",
//       "code": "ky"
//     },
//     {
//       "language": "Lao",
//       "code": "lo"
//     },
//     {
//       "language": "Latin",
//       "code": "la"
//     },
//     {
//       "language": "Latvian",
//       "code": "lv"
//     },
//     {
//       "language": "Lithuanian",
//       "code": "lt"
//     },
//     {
//       "language": "Luxembourgish",
//       "code": "lb"
//     },
//     {
//       "language": "Macedonian",
//       "code": "mk"
//     },
//     {
//       "language": "Malagasy",
//       "code": "mg"
//     },
//     {
//       "language": "Malay",
//       "code": "ms"
//     },
//     {
//       "language": "Malayalam",
//       "code": "ml"
//     },
//     {
//       "language": "Maltese",
//       "code": "mt"
//     },
//     {
//       "language": "Maori",
//       "code": "mi"
//     },
//     {
//       "language": "Marathi",
//       "code": "mr"
//     },
//     {
//       "language": "Mongolian",
//       "code": "mn"
//     },
//     {
//       "language": "Myanmar (Burmese)",
//       "code": "my"
//     },
//     {
//       "language": "Nepali",
//       "code": "ne"
//     },
//     {
//       "language": "Norwegian",
//       "code": "no"
//     },
//     {
//       "language": "Nyanja (Chichewa)",
//       "code": "ny"
//     },
//     {
//       "language": "Pashto",
//       "code": "ps"
//     },
//     {
//       "language": "Persian",
//       "code": "fa"
//     },
//     {
//       "language": "Polish",
//       "code": "pl"
//     },
//     {
//       "language": "Portuguese (Portugal, Brazil)",
//       "code": "pt"
//     },
//     {
//       "language": "Punjabi",
//       "code": "pa"
//     },
//     {
//       "language": "Romanian",
//       "code": "ro"
//     },
//     {
//       "language": "Russian",
//       "code": "ru"
//     },
//     {
//       "language": "Samoan",
//       "code": "sm"
//     },
//     {
//       "language": "Scots Gaelic",
//       "code": "gd"
//     },
//     {
//       "language": "Serbian",
//       "code": "sr"
//     },
//     {
//       "language": "Sesotho",
//       "code": "st"
//     },
//     {
//       "language": "Shona",
//       "code": "sn"
//     },
//     {
//       "language": "Sindhi",
//       "code": "sd"
//     },
//     {
//       "language": "Sinhala (Sinhalese)",
//       "code": "si"
//     },
//     {
//       "language": "Slovak",
//       "code": "sk"
//     },
//     {
//       "language": "Slovenian",
//       "code": "sl"
//     },
//     {
//       "language": "Somali",
//       "code": "so"
//     },
//     {
//       "language": "Spanish",
//       "code": "es"
//     },
//     {
//       "language": "Sundanese",
//       "code": "su"
//     },
//     {
//       "language": "Swahili",
//       "code": "sw"
//     },
//     {
//       "language": "Swedish",
//       "code": "sv"
//     },
//     {
//       "language": "Tagalog (Filipino)",
//       "code": "tl"
//     },
//     {
//       "language": "Tajik",
//       "code": "tg"
//     },
//     {
//       "language": "Tamil",
//       "code": "ta"
//     },
//     {
//       "language": "Telugu",
//       "code": "te"
//     },
//     {
//       "language": "Thai",
//       "code": "th"
//     },
//     {
//       "language": "Turkish",
//       "code": "tr"
//     },
//     {
//       "language": "Ukrainian",
//       "code": "uk"
//     },
//     {
//       "language": "Urdu",
//       "code": "ur"
//     },
//     {
//       "language": "Uzbek",
//       "code": "uz"
//     },
//     {
//       "language": "Vietnamese",
//       "code": "vi"
//     },
//     {
//       "language": "Welsh",
//       "code": "cy"
//     },
//     {
//       "language": "Xhosa",
//       "code": "xh"
//     },
//     {
//       "language": "Yiddish",
//       "code": "yi"
//     },
//     {
//       "language": "Yoruba",
//       "code": "yo"
//     },
//     {
//       "language": "Zulu",
//       "code": "zu"
//     }
//   ]







//   const [inputValue, setInputValue] = useState('');
//   const [welcomeApiMsg, setWelcomeApiMsg] = useState('');
//   const [staticApiVideo, setStaticApiVideo] = useState('');
//   const [welcomeApiVideo, setWelcomeApiVideo] = useState('');
//   const [messages, setMessages] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [videoUrl, setVideoUrl] = useState('');




//   const [audioToText, setAudioToText] = useState('');

//   const [recording, setRecording] = useState(false);
//   const [audioBlob, setAudioBlob] = useState(null);
//   const [timer, setTimer] = useState(0);
//   const [isSoundOn, setIsSoundOn] = useState(true);
//   const [videoLoading, setVideoLoading] = useState(true);
//   const [showChatBot, setShowChatBot] = useState(false);

//   const [recordMessage, setRecordMessage] = useState(false);

//   const [isApiCalled, setIsApiCalled] = useState(false);

//   const videoRef = useRef(null);
//   const mediaRecorderRef = useRef(null);
//   const audioChunksRef = useRef([]);
//   const timerRef = useRef(null);
//   const chatHeadRef = useRef(null);
//   const messagesEndRef = useRef(null); // Ref for the autoscroll
//   const inputRef = useRef(null);

//   const dropdownAvatarRef = useRef(null);
//   const dropdownLanguageRef = useRef(null);

//   const searchInputRef = useRef(null);



//   const [showInitialText, setShowInitialText] = useState(true);
//   const [recentBotIndex, setRecentBotIndex] = useState(null);

//   const [showWelcomeMsg, setShowWelcomeMsg] = useState(false);
//   const [checkAudio, setCheckAudio] = useState(false);

//   const [showWelcomeVideo, setShowWelcomeVideo] = useState(true);

//   const [showLanguages, setShowLanguages] = useState(false);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filteredLanguages, setFilteredLanguages] = useState(languages);



//   const [showAvatar, setShowAvatar] = useState(false);
//   const [selectedLanguage, setSelectedLanguage] = useState('English');
//   const [languageCode, setLanguageCode] = useState('en');




//   const [isRecordingTemp, setIsRecordingTemp] = useState(false);
//   const [audioUrlTemp, setAudioUrlTemp] = useState(null);
//   const [apiResponseTemp, setApiResponseTemp] = useState('');
//   const mediaRecorderRefTemp = useRef(null);
//   const recordedChunksRefTemp = useRef([]);
//   const mediaStreamRefTemp = useRef(null);


//   const audioRef = useRef(null); 

//   const audioConstraintsTemp = {
//     audio: {
//       sampleRate: 16000,
//       channelCount: 1,
//       echoCancellation: false,
//       noiseSuppression: false,
//       autoGainControl: false,
//     },
//   };




//   // taking params from parent app and passing in iframe api

//   // const [params, setParams] = useState({
//   //   companyName: '',
//   //   moduleName: '',
//   //   avatarName: ''
//   // });

//   // useEffect(() => {
//   //   const receiveMessage = (event) => {

//   //     const { companyName, moduleName, avatarName } = event.data;
//   //     setParams({ companyName, moduleName, avatarName });

//   //   };

//   //   window.addEventListener('message', receiveMessage, false);

//   //   return () => {
//   //     window.removeEventListener('message', receiveMessage);
//   //   };
//   // }, []);





//   // taking params from iframe url only and passing in iframe api


//   const params = useLocation();

//   const pathSegments = params.pathname.split('/').filter(segment => segment);

//   const companyName = pathSegments[pathSegments.length - 3];
//   const moduleName = pathSegments[pathSegments.length - 2];
//   const avatarName = pathSegments[pathSegments.length - 1];








//   const avatarList = [
//     { name: 'Natasha', image: 'image1.png' },
//     // { name: 'Avatar2', image: 'image2.png' },
//   ];




//   const defaultAvatar = avatarList[0];

//   const [selectedAvatar, setSelectedAvatar] = useState({
//     name: defaultAvatar.name,
//     image: defaultAvatar.image,
//   });

//   useEffect(() => {

//     if (avatarName) {
//       const foundAvatar = avatarList.find(avatar => avatar.name.toLowerCase() === avatarName.toLowerCase());

//       if (foundAvatar) {
//         setSelectedAvatar({
//           name: foundAvatar.name,
//           image: foundAvatar.image,
//         });
//       } else {
//         setSelectedAvatar(defaultAvatar);
//       }
//     } else {
//       setSelectedAvatar(defaultAvatar);
//     }
//   }, [avatarName]);



//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownAvatarRef.current && !dropdownAvatarRef.current.contains(event.target)) {
//         setShowAvatar(false);
//       }
//     };

//     if (showAvatar) {
//       document.addEventListener('mousedown', handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [showAvatar]);





//   const handleAvatarToggle = () => {
//     setShowAvatar(!showAvatar);
//   };

//   const handleLanguageToggle = () => {
//     setShowLanguages(!showLanguages);
//     if (!showLanguages) {
//       setFilteredLanguages(languages);
//       setSearchTerm('');
//     }
//   };

//   const handleLanguageSelect = (language) => {
//     setSelectedLanguage(language.language);
//     setSearchTerm('');
//     setFilteredLanguages(languages);
//     setShowLanguages(false);
//     setLanguageCode(language.code)
//   };



//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value);
//     const filtered = languages.filter((language) =>
//       language.language.toLowerCase().includes(event.target.value.toLowerCase())
//     );
//     setFilteredLanguages(filtered);
//   };


//   useEffect(() => {
//     if (showLanguages) {
//       searchInputRef.current.focus();
//     }
//   }, [showLanguages]);


//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownLanguageRef.current && !dropdownLanguageRef.current.contains(event.target)) {
//         setShowLanguages(false);
//       }
//     };

//     if (showLanguages) {
//       document.addEventListener('mousedown', handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [showLanguages]);

//   const handleKeyDown = (event) => {
//     if (event.key === 'Enter' && filteredLanguages.length > 0) {
//       handleLanguageSelect(filteredLanguages[0]);
//     }
//   };



//   const handleAvatarSelect = (avatar) => {
//     setSelectedAvatar(avatar);
//     setShowAvatar(false);
//   };



//   const handleWelcomeVideoEnd = () => {
//     setShowWelcomeVideo(false);
//   };

//   useEffect(() => {

//     const showTimeout = setTimeout(() => {
//       setShowWelcomeMsg(true);
//     }, 1);

//     const hideTimeout = setTimeout(() => {
//       setShowWelcomeMsg(false);
//     }, 3000);

//     return () => {
//       clearTimeout(showTimeout);
//       clearTimeout(hideTimeout);
//     };
//   }, []);



//   useEffect(() => {
//     const callApiOnPageLoad = async () => {
//       const payload = { user_input: 'hi' };

//       try {
//         const response = await fetch(
//           `https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${selectedAvatar.name.toLowerCase()}/`,
//           {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(payload),
//           }
//         );

//         if (response.ok) {
//           const data = await response.json();
//           setWelcomeApiMsg(data.responses[0])
//           setStaticApiVideo(data.static_video)
//           setWelcomeApiVideo(data.video_path)
//         } else {
//           console.error('API error:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Fetch error:', error);
//       }
//     };

//     callApiOnPageLoad();
//   }, [companyName, moduleName, selectedAvatar]);





//   useEffect(() => {
//     const latestBotIndex = messages
//       .map((item, index) => (item.type === 'bot' ? index : null))
//       .filter(index => index !== null)
//       .pop();

//     if (latestBotIndex !== null) {
//       setRecentBotIndex(latestBotIndex);

//       const timer = setTimeout(() => {
//         setShowInitialText(false);
//       }, 1300);

//       setShowInitialText(true);

//       return () => clearTimeout(timer);
//     }
//   }, [messages]);

//   const handleKeyPress = (event) => {
//     if (event.key === 'Enter') {
//       handleSendMessage();
//     }
//   };


//   const handleSendMessage = async () => {
//     setInputValue(''); // Clear the input field at the start

//     if (inputValue.trim() || audioToText) {
//       setLoading(true);
//       setIsApiCalled(true);

//       const userMessage = {
//         message: audioToText || inputValue.trim() || '',
//         type: 'user',
//         audioUrl: audioToText,
//         isActive: true,
//       };

//       setMessages((prevMessages) => [...prevMessages, userMessage]);

//       const payload = {
//         user_input: audioToText ? audioToText : inputValue.trim() || '',
//         lang: languageCode,
//         companyName: companyName,
//         moduleName: moduleName,
//         avatarName: selectedAvatar.name,
//       };

//       if (messagesEndRef.current) {
//         messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//       }

//       try {
//         const response = await fetch(`https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${selectedAvatar.name.toLowerCase()}/`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(payload),
//         });

//         const data = await response.json();

//         setAudioToText('');
//         setAudioBlob(null);
//         setTimer(0);

//         handleResponse(data); // Call handleResponse to play audio and video

//         if (messagesEndRef.current) {
//           messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//         }

//       } catch (error) {
//         console.error('Error:', error);
//       } finally {
//         setLoading(false);
//       }
//     }
//   };





//   const handleResponse = (data) => {
//     // Check if there are bot responses
//     if (data.responses && data.responses.length > 0) {
//       const botMessage = data.responses.join(' ');
//       setMessages((prevMessages) => [
//         ...prevMessages,
//         {
//           message: botMessage,
//           type: 'bot',
//         },
//       ]);
//     } else {
//       setMessages((prevMessages) => [
//         ...prevMessages,
//         {
//           message: "Could you please repeat?",
//           type: 'bot',
//         },
//       ]);
//     }

//     // Check if there is a video path in the response
//     if (data.video_path) {
//       const fullVideoUrl = data.video_path;
//       setVideoUrl(fullVideoUrl);

//       // If there is audio data in base64 format, handle the audio
//       if (data.audio_data) {
//         // Convert the base64 audio data to a Blob
//         const audioBlob = base64ToBlob(data.audio_data, 'audio/mp3'); // Assuming audio is in mp3 format
//         const audioUrl = URL.createObjectURL(audioBlob); // Create a URL for the audio Blob





//         // Create audio and video elements
//         const videoElement = document.createElement('video');
//         videoElement.src = fullVideoUrl;
//         videoElement.controls = true;
//         videoElement.autoplay = true;
//         videoElement.muted = true; // Mute the video to avoid overlapping sound

//         const audioElement = document.createElement('audio');
//         audioElement.src = audioUrl;
//         audioElement.autoplay = true;


//         // const audioElement = new Audio(audioUrl);
//       audioRef.current = audioElement; 

//         // Sync the audio and video playback
//         videoElement.addEventListener('play', () => {
//           audioElement.play();
//         });

//         videoElement.addEventListener('pause', () => {
//           audioElement.pause();
//         });

//         // Append the video element to a container (assuming there is a container for the video)
//         const videoContainer = document.getElementById('video-container');
//         videoContainer.innerHTML = ''; // Clear any previous video
//         videoContainer.appendChild(videoElement);
//       } else {
//         console.log('No audio data received.');
//       }
//     } else {
//       setVideoUrl(''); // Clear the video if no path is provided
//     }
//   };

//   // Utility function to convert base64 to Blob
//   const base64ToBlob = (base64Data, contentType = '', sliceSize = 512) => {
//     const byteCharacters = atob(base64Data);
//     const byteArrays = [];

//     for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//       const slice = byteCharacters.slice(offset, offset + sliceSize);

//       const byteNumbers = new Array(slice.length);
//       for (let i = 0; i < slice.length; i++) {
//         byteNumbers[i] = slice.charCodeAt(i);
//       }

//       const byteArray = new Uint8Array(byteNumbers);
//       byteArrays.push(byteArray);
//     }

//     return new Blob(byteArrays, { type: contentType });
//   };







//   useEffect(() => {
//     if (videoUrl) {
//       setVideoLoading(true);
//       const video = videoRef.current;
//       if (video) {
//         video.onloadeddata = () => {
//           setVideoLoading(false);
//           video.onended = () => {
//             setVideoUrl('');
//           };
//         };
//       }

//     }
//   }, [videoUrl]);




//   // useEffect(() => {
//   //   if (!loading && !recording) {
//   //     inputRef.current?.focus();
//   //   }
//   //   if (showWelcomeVideo) {
//   //     inputRef.current?.focus();
//   //   }
//   // }, [loading, recording, inputValue, messages]);


//   // recording check 


//   // const startRecording = async () => {
//   //   setRecording(true);
//   //   setRecordMessage(false)
//   //   setTimer(0);
//   //   audioChunksRef.current = [];
//   //   const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//   //   mediaRecorderRef.current = new MediaRecorder(stream);
//   //   mediaRecorderRef.current.ondataavailable = (event) => {
//   //     if (event.data.size > 0) {
//   //       audioChunksRef.current.push(event.data);
//   //     }
//   //   };
//   //   mediaRecorderRef.current.onstop = () => {
//   //     const blob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
//   //     setAudioBlob(blob);
//   //   };
//   //   mediaRecorderRef.current.start();
//   //   timerRef.current = setInterval(() => setTimer((prev) => prev + 1), 1000);
//   // };

//   // const stopRecording = () => {
//   //   setRecordMessage(true)
//   //   setRecording(false);
//   //   if (mediaRecorderRef.current) {
//   //     mediaRecorderRef.current.stop();
//   //   }
//   //   clearInterval(timerRef.current);
//   // };

//   useEffect(() => {
//     if (audioBlob) {
//       sendAudioToApi(audioBlob);
//     }
//   }, [audioBlob]);


//   useEffect(() => {
//     const timer = setTimeout(() => {

//       setRecordMessage(false);
//     }, 3000);

//     return () => clearTimeout(timer);
//   }, [recordMessage]);

//   // const sendAudioToApi = async (blob) => {
//   //   try {
//   //     const base64Audio = await convertBlobToBase64(blob);

//   //     const fileExtension = blob.type.split('/')[1];

//   //     const formData = new FormData();
//   //     formData.append('audio_file', base64Audio.split(',')[1]);
//   //     formData.append('file_extension', fileExtension);


//   //     const response = await fetch('https://speech.pranathiss.com/api/vad-base64-speech-to-text/', {
//   //       method: 'POST',
//   //       body: formData
//   //     });

//   //     const data = await response.json();
//   //     setAudioToText(data.response)
//   //     if (data.response.length > 0) {
//   //       setCheckAudio(false)
//   //     }

//   //     // if (data.response) {
//   //     //   setTimeout(() => {
//   //     //     setRecordMessage(false)
//   //     //     }, 880);
//   //     //   // }, 6200);
//   //     // }


//   //   } catch (error) {
//   //     console.error('Error sending audio:', error);
//   //   }
//   //   finally {
//   //     setCheckAudio(true)
//   //   }
//   // };


//   const convertBlobToBase64 = (blob) => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onloadend = () => resolve(reader.result);
//       reader.onerror = reject;
//       reader.readAsDataURL(blob);
//     });
//   };


//   useEffect(() => {
//     if (videoRef.current) {
//       setVideoLoading(true);
//       videoRef.current.load();
//       videoRef.current.play().finally(() => {
//         setVideoLoading(false);
//       });
//     }
//   }, [videoUrl]);



//   const toggleSound = () => {
//     if (audioRef.current) {
//       audioRef.current.muted = isSoundOn;
//       setIsSoundOn(!isSoundOn);
//     }
//   };




//   // const toggleSound = () => {
//   //   if (videoRef.current && audioRef.current) {
//   //     if (isSoundOn) {
//   //       // Mute both video and external audio
//   //       videoRef.current.muted = true;
//   //       audioRef.current.muted = true; // Mute external audio
//   //     } else {
//   //       // Unmute both video and external audio
//   //       videoRef.current.muted = false;
//   //       audioRef.current.muted = false; // Unmute external audio
//   //     }
//   //     // Toggle sound state
//   //     setIsSoundOn(!isSoundOn);
//   //   }
//   // };











//   const handleChatBotOpen = () => {
//     setShowChatBot(!showChatBot);
//   };

//   useEffect(() => {
//     setShowChatBot(true);
//   }, []);


//   useEffect(() => {
//     handleSendMessage()
//   }, [audioToText])
//   const handleVideoEnd = () => {
//     setVideoUrl(staticApiVideo)
//   }


//   useEffect(() => {
//     if (messagesEndRef.current) {
//       setTimeout(() => {
//         if (messagesEndRef.current) {
//           messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//         }
//       }, 500);
//     }
//   }, [messages, showChatBot]);



//   // audio functionality for android

//   // const sendAudioToApi = async () => {
//   //   if (!isRecordingTemp) {
//   //     try {
//   //       mediaStreamRefTemp.current = await navigator.mediaDevices.getUserMedia(audioConstraintsTemp);
//   //       mediaRecorderRefTemp.current = new MediaRecorder(mediaStreamRefTemp.current, { mimeType: 'audio/webm' });

//   //       mediaRecorderRefTemp.current.ondataavailable = (event) => {
//   //         if (event.data.size > 0) {
//   //           recordedChunksRefTemp.current.push(event.data);
//   //         }
//   //       };

//   //       mediaRecorderRefTemp.current.onstop = async () => {
//   //         const blobTemp = new Blob(recordedChunksRefTemp.current, { type: 'audio/webm' });
//   //         recordedChunksRefTemp.current = [];

//   //         // Convert blob to Base64
//   //         const readerTemp = new FileReader();
//   //         readerTemp.readAsDataURL(blobTemp);
//   //         readerTemp.onloadend = async () => {
//   //           const base64AudioTemp = readerTemp.result.split(',')[1];

//   //           // Display audio for playback
//   //           const audioUrlTemp = URL.createObjectURL(blobTemp);
//   //           setAudioUrlTemp(audioUrlTemp);

//   //           // Prepare the form data
//   //           const formDataTemp = new FormData();
//   //           formDataTemp.append('audio_file', base64AudioTemp);
//   //           formDataTemp.append('file_extension', 'webm');

//   //           // Call the API
//   //           try {
//   //             const responseTemp = await fetch('https://speech.pranathiss.com/api/vad-base64-speech-to-text/', {
//   //               method: 'POST',
//   //               body: formDataTemp,
//   //             });

//   //             const resultTemp = await responseTemp.json();
//   //             console.log('pppppppppppp', resultTemp.response);

//   //             setApiResponseTemp(JSON.stringify(resultTemp, null, 2));
//   //             setAudioToText(resultTemp.response)
//   //             if (resultTemp.response.length > 0) {
//   //               setCheckAudio(false)
//   //             }
//   //           } catch (error) {
//   //             console.error('Error calling the API:', error);
//   //             setApiResponseTemp(`Error: ${error.message}`);
//   //           }
//   //         };
//   //       };

//   //       mediaRecorderRefTemp.current.start();
//   //       setIsRecordingTemp(true);
//   //     } catch (error) {
//   //       console.error('Error accessing microphone: ', error);
//   //     }
//   //   } else {
//   //     // Stop the recording
//   //     mediaRecorderRefTemp.current.stop();
//   //     mediaStreamRefTemp.current.getTracks().forEach((track) => track.stop());
//   //     setIsRecordingTemp(false);
//   //   }
//   // };





//   // audio functionality for ios 

//   const sendAudioToApi = async () => {
//     if (!isRecordingTemp) {
//       try {
//         mediaStreamRefTemp.current = await navigator.mediaDevices.getUserMedia(audioConstraintsTemp);

//         // Detect if the user is on iOS and use 'audio/mp4' else 'audio/webm'
//         const mimeType = isIOS() ? 'audio/mp4' : 'audio/webm';
//         mediaRecorderRefTemp.current = new MediaRecorder(mediaStreamRefTemp.current, { mimeType });

//         mediaRecorderRefTemp.current.ondataavailable = (event) => {
//           if (event.data.size > 0) {
//             recordedChunksRefTemp.current.push(event.data);
//           }
//         };

//         mediaRecorderRefTemp.current.onstop = async () => {
//           const blobTemp = new Blob(recordedChunksRefTemp.current, { type: mimeType });
//           recordedChunksRefTemp.current = [];

//           // Convert blob to Base64
//           const readerTemp = new FileReader();
//           readerTemp.readAsDataURL(blobTemp);
//           readerTemp.onloadend = async () => {
//             const base64AudioTemp = readerTemp.result.split(',')[1];

//             // Display audio for playback
//             const audioUrlTemp = URL.createObjectURL(blobTemp);
//             setAudioUrlTemp(audioUrlTemp);

//             // Prepare the form data
//             const formDataTemp = new FormData();
//             formDataTemp.append('audio_file', base64AudioTemp);
//             formDataTemp.append('file_extension', mimeType === 'audio/mp4' ? 'mp4' : 'webm');

//             // Call the API
//             try {
//               const responseTemp = await fetch('https://speech.pranathiss.com/api/vad-base64-speech-to-text/', {
//                 method: 'POST',
//                 body: formDataTemp,
//               });

//               const resultTemp = await responseTemp.json();
//               console.log('API Response:', resultTemp.response);

//               setApiResponseTemp(JSON.stringify(resultTemp, null, 2));
//               setAudioToText(resultTemp.response);

//               if (resultTemp.response.length > 0) {
//                 setCheckAudio(false);
//               }
//             } catch (error) {
//               console.error('Error calling the API:', error);
//               setApiResponseTemp(`Error: ${error.message}`);
//             }
//           };
//         };

//         mediaRecorderRefTemp.current.start();
//         setIsRecordingTemp(true);
//       } catch (error) {
//         console.error('Error accessing microphone: ', error);
//       }
//     } else {
//       // Stop the recording
//       mediaRecorderRefTemp.current.stop();
//       mediaStreamRefTemp.current.getTracks().forEach((track) => track.stop());
//       setIsRecordingTemp(false);
//     }
//   };

//   // Helper function to check if the user is on iOS
//   const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);











//   return (

//     <>
//       {
//         !showChatBot ?
//           <div className="videochartvalue ">
//             <div className='container'>

//               <div className='headAndOutput'>

//                 <div className='chatHead'>
//                   <div className='headIcontext'>
//                     <div className='group-2'></div>
//                     <div className='iconHeadChat'>
//                       <img src="/img/chatAvatarIcon3.png" alt="Avatar" />
//                       <div className='headTextClass'>
//                         <div className="text-wrapper miniHead">Chat with</div>
//                         <div className="text-wrapper-2">AI Assistant</div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className='headMinClose'>

//                     <div className='languagesDivBoth'>
//                       <div className='languagesDiv' onClick={handleAvatarToggle} ref={dropdownAvatarRef} >
//                         <div className='avatarNameImgClass'>
//                           {selectedAvatar.name}

//                         </div>
//                         <span className={`arrow ${showAvatar ? 'open' : ''}`}>
//                           <img src={arrowDown} alt="" className='downArrowClass' />
//                         </span>
//                         {showAvatar && (
//                           <ul className='languageDropdown'>
//                             {avatarList
//                               .filter(avatar => avatar !== selectedAvatar)
//                               .map((avatar, index) => (
//                                 <li key={index} onClick={() => handleAvatarSelect(avatar)} className='avatarNameImgClass'>

//                                   {avatar.name}
//                                 </li>
//                               ))}
//                           </ul>
//                         )}
//                       </div>


//                       <div ref={dropdownLanguageRef} className='languagesDiv' onClick={handleLanguageToggle}>

//                         {selectedLanguage}

//                         <span className={`arrow ${showLanguages ? 'open' : ''}`}>
//                           <img src={arrowDown} alt="" className='downArrowClass' />
//                         </span>

//                         {showLanguages && (
//                           <ul className='languageDropdown'>

//                             <input
//                               ref={searchInputRef}
//                               type="text"
//                               value={searchTerm}
//                               onChange={handleSearchChange}
//                               onKeyDown={handleKeyDown}
//                               placeholder="Search"
//                               className='searchLanguageInput'
//                             />
//                           </ul>
//                         )}
//                         {showLanguages && (
//                           <ul className='languageDropdown languageSearchDropdown'>

//                             {filteredLanguages.map((language, index) => (
//                               <li key={index} onClick={() => handleLanguageSelect(language)} className='languageOption'>
//                                 <p>

//                                   {language.language}
//                                 </p>
//                               </li>
//                             ))}
//                           </ul>
//                         )}
//                       </div>




//                     </div>

//                     <div className=' speakerIconButton' >
//                       {isSoundOn ? (
//                         <img src={chatSoundIconWhite} alt="Speaker" onClick={toggleSound} />
//                       ) : (
//                         <img src={chatSpeakerMuteIcon} alt="Speaker" onClick={toggleSound} />
//                       )}
//                       {/* <img src="/img/minimizeChatWhite.png" alt="Line" onClick={handleChatBotOpen} /> */}
//                       <img src="/img/minimizeIcon/minimize1.png" alt="Line" onClick={handleChatBotOpen} />
//                     </div>


//                   </div>
//                 </div>



//                 <div id="output-container">
//                   <div className="messages-container">
//                     {
//                       showWelcomeMsg ?


//                         <div className='message-item receiver'>
//                           <div className='senderIconText'>

//                             <div className='message'>
//                               <div className="chat-bubble">
//                                 <div className="typing">
//                                   <div className="dot"></div>
//                                   <div className="dot"></div>
//                                   <div className="dot"></div>
//                                 </div>
//                               </div>
//                             </div>
//                             <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />
//                           </div>
//                         </div>

//                         :
//                         <div className='receiverIconText'>
//                           <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />
//                           <div className="success-status">
//                             {welcomeApiMsg}
//                           </div>
//                         </div>
//                     }



//                     {messages.map((item, index) => (
//                       <div key={index} className={`message-item ${item.type === 'user' ? 'sender' : 'receiver'}`}>
//                         <div className='senderIconText'>
//                           <div className="message">
//                             {
//                               item.audioUrl ?
//                                 recordMessage && index === recentBotIndex ? (
//                                   <div className="chat-bubble-audio">
//                                     <div className="typing">
//                                       <div className="dot"></div>
//                                       <div className="dot"></div>
//                                       <div className="dot"></div>
//                                     </div>
//                                   </div>
//                                 )
//                                   : (
//                                     <p>{item.audioUrl}</p>
//                                   )
//                                 :
//                                 //  (
//                                 //   item.type === 'bot' && index === recentBotIndex && showInitialText ? (
//                                 //     <div className="chat-bubble">
//                                 //       <div className="typing">
//                                 //         <div className="dot"></div>
//                                 //         <div className="dot"></div>
//                                 //         <div className="dot"></div>
//                                 //       </div>
//                                 //     </div>
//                                 //   ) : (
//                                 //     <p>{item.message}</p>
//                                 //   )
//                                 // )
//                                 <p>{item.message}</p>

//                             }
//                           </div>
//                           {item.type === 'user' && <img src="/img/chatSenderIcon.png" alt="Sender Avatar" />}
//                           {item.type === 'bot' && <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />}
//                         </div>
//                         <div ref={messagesEndRef} /> {/* Auto-scroll target */}
//                       </div>
//                     ))}

//                     {/* {recordMessage ?
//                     <div className="senderIconText">
//                       <div className="message">
//                         <div className="typing">
//                           <div className="dot"></div>
//                           <div className="dot"></div>
//                           <div className="dot"></div>
//                         </div>
//                       </div>
//                       <img src="/img/chatSenderIcon.png" alt="Sender Avatar" />

//                     </div>
//                     : ""} */}

//                     <div ref={messagesEndRef} />
//                   </div>





//                   <div className="hello-container">


//                     {showWelcomeVideo && welcomeApiVideo ? (
//                       // If welcome video is available and should be shown
//                       <video
//                         ref={videoRef}
//                         width="100%"
//                         autoPlay
//                         muted={!isSoundOn}
//                         onEnded={handleWelcomeVideoEnd}
//                       >
//                         <source src={welcomeApiVideo} type="video/mp4" />
//                         Your browser does not support the video tag.
//                       </video>
//                     ) : (
//                       <div>
//                         {/* Fallback to static video if no API welcome video is available */}
//                         {!isApiCalled || !videoUrl ? (
//                           <video
//                             ref={videoRef}
//                             width="100%"
//                             autoPlay
//                             loop
//                             muted
//                           >
//                             <source src={staticApiVideo} type="video/mp4" />
//                             Your browser does not support the video tag.
//                           </video>
//                         ) : (
//                           <video
//                             ref={videoRef}
//                             width="100%"
//                             autoPlay
//                             // muted={!isSoundOn}
//                             onEnded={handleVideoEnd}
//                             muted
//                           >
//                             {/* <source src={videoUrl} type="video/mp4" muted/> */}
//                             <source src={videoUrl} type="video/mp4" />
//                             Your browser does not support the video tag.
//                           </video>
//                         )}
//                       </div>
//                     )}

//                   </div>


//                 </div>

//               </div>

//               <div className='outputHelpfulClass'>

//                 <div className="input-container">
//                   <input
//                     type="text"
//                     value={inputValue}
//                     onChange={(e) => setInputValue(e.target.value)}
//                     onKeyPress={handleKeyPress}
//                     placeholder="Enter your message"
//                     disabled={loading || recording}
//                     ref={inputRef}
//                   />

//                   {/* {recording ? ( */}
//                   {isRecordingTemp ? (
//                     <div className="recording-indicator">
//                       <div onClick={sendAudioToApi} disabled={loading} className='iconButton'>
//                         <img src="/img/chatMicSpeakIcon.png" alt="Mic" />
//                       </div>
//                     </div>
//                   ) : (
//                     <div className='iconButton' onClick={sendAudioToApi} disabled={loading}>
//                       <img src="/img/chatMicIcon.png" alt="Mic" />
//                     </div>
//                   )}

//                   <div className='iconButton' onClick={handleSendMessage} disabled={loading || recording}>
//                     <img src="/img/chatSendIcon.png" alt="Send" />
//                   </div>
//                 </div>
//               </div>
//             </div>

//           </div>
//           :
//           <div className='chatBoxImageClass' >
//             <img src={chatBoxImage} alt="" onClick={handleChatBotOpen} />

//             {/* <div style={{ fontFamily: 'Arial, sans-serif', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
//               <button onClick={handleRecordTemp} style={{ padding: '10px 20px', fontSize: '16px', marginBottom: '20px', cursor: 'pointer' }}>
//                 {isRecordingTemp ? 'Stop Recording' : 'Start Recording'}
//               </button>
//               <audio controls src={audioUrlTemp} style={{ marginTop: '20px' }} />
//               <pre style={{ backgroundColor: '#f4f4f4', padding: '10px', borderRadius: '5px', maxWidth: '80%', whiteSpace: 'pre-wrap' }}>
//                 {apiResponseTemp}
//               </pre>
//             </div> */}


//           </div>
//       }

//     </>

//   );
// };

// export default MessageInput;















// audio from api , iphone compatibilty



// import React, { useState, useRef, useEffect } from 'react';
// import Picker from 'emoji-picker-react';
// import './frameNew.css';


// import chatBoxImage from '../../../static/img/chatBoxImageCrop1.png'
// import arrowDown from '../../../static/img/arrowDownConvert.png'

// import chatSoundIconWhite from '../../../static/img/chatSoundSpeaker.png'
// import chatSpeakerMuteIcon from '../../../static/img/chatMuteSpeaker.png'
// import { useFetcher, useLocation, useParams } from 'react-router-dom';



// const MessageInput = () => {


//   // const avatarList = [
//   //   { name: 'Shweta', image: '/img/avatar/woman.png' },
//   //   { name: 'Jensey', image: '/img/avatar/avatarAman.svg' },
//   //   { name: 'Aman', image: '/img/avatar/man.png' },
//   //   { name: 'Salman', image: '/img/avatar/avatarAman.svg' },
//   //   { name: 'Alex', image: '/img/avatar/man.png' },
//   //   { name: 'Dharmesh', image: '/img/avatar/woman.png' },
//   // ];



//   const languages = [
//     {
//       "language": "Afrikaans",
//       "code": "af"
//     },
//     {
//       "language": "Albanian",
//       "code": "sq"
//     },
//     {
//       "language": "Amharic",
//       "code": "am"
//     },
//     {
//       "language": "Arabic",
//       "code": "ar"
//     },
//     {
//       "language": "Armenian",
//       "code": "hy"
//     },
//     {
//       "language": "Azerbaijani",
//       "code": "az"
//     },
//     {
//       "language": "Basque",
//       "code": "eu"
//     },
//     {
//       "language": "Belarusian",
//       "code": "be"
//     },
//     {
//       "language": "Bengali",
//       "code": "bn"
//     },
//     {
//       "language": "Bosnian",
//       "code": "bs"
//     },
//     {
//       "language": "Bulgarian",
//       "code": "bg"
//     },
//     {
//       "language": "Catalan",
//       "code": "ca"
//     },
//     {
//       "language": "Cebuano",
//       "code": "ceb"
//     },
//     {
//       "language": "Chinese (Simplified)",
//       "code": "zh-CN"
//     },
//     {
//       "language": "Chinese (Traditional)",
//       "code": "zh-TW"
//     },
//     {
//       "language": "Corsican",
//       "code": "co"
//     },
//     {
//       "language": "Croatian",
//       "code": "hr"
//     },
//     {
//       "language": "Czech",
//       "code": "cs"
//     },
//     {
//       "language": "Danish",
//       "code": "da"
//     },
//     {
//       "language": "Dutch",
//       "code": "nl"
//     },
//     {
//       "language": "English",
//       "code": "en"
//     },
//     {
//       "language": "Esperanto",
//       "code": "eo"
//     },
//     {
//       "language": "Estonian",
//       "code": "et"
//     },
//     {
//       "language": "Finnish",
//       "code": "fi"
//     },
//     {
//       "language": "French",
//       "code": "fr"
//     },
//     {
//       "language": "Frisian",
//       "code": "fy"
//     },
//     {
//       "language": "Galician",
//       "code": "gl"
//     },
//     {
//       "language": "Georgian",
//       "code": "ka"
//     },
//     {
//       "language": "German",
//       "code": "de"
//     },
//     {
//       "language": "Greek",
//       "code": "el"
//     },
//     {
//       "language": "Gujarati",
//       "code": "gu"
//     },
//     {
//       "language": "Haitian Creole",
//       "code": "ht"
//     },
//     {
//       "language": "Hausa",
//       "code": "ha"
//     },
//     {
//       "language": "Hawaiian",
//       "code": "haw"
//     },
//     {
//       "language": "Hebrew",
//       "code": "iw"
//     },
//     {
//       "language": "Hindi",
//       "code": "hi"
//     },
//     {
//       "language": "Hmong",
//       "code": "hmn"
//     },
//     {
//       "language": "Hungarian",
//       "code": "hu"
//     },
//     {
//       "language": "Icelandic",
//       "code": "is"
//     },
//     {
//       "language": "Igbo",
//       "code": "ig"
//     },
//     {
//       "language": "Indonesian",
//       "code": "id"
//     },
//     {
//       "language": "Irish",
//       "code": "ga"
//     },
//     {
//       "language": "Italian",
//       "code": "it"
//     },
//     {
//       "language": "Japanese",
//       "code": "ja"
//     },
//     {
//       "language": "Javanese",
//       "code": "jw"
//     },
//     {
//       "language": "Kannada",
//       "code": "kn"
//     },
//     {
//       "language": "Kazakh",
//       "code": "kk"
//     },
//     {
//       "language": "Khmer",
//       "code": "km"
//     },
//     {
//       "language": "Korean",
//       "code": "ko"
//     },
//     {
//       "language": "Kurdish",
//       "code": "ku"
//     },
//     {
//       "language": "Kyrgyz",
//       "code": "ky"
//     },
//     {
//       "language": "Lao",
//       "code": "lo"
//     },
//     {
//       "language": "Latin",
//       "code": "la"
//     },
//     {
//       "language": "Latvian",
//       "code": "lv"
//     },
//     {
//       "language": "Lithuanian",
//       "code": "lt"
//     },
//     {
//       "language": "Luxembourgish",
//       "code": "lb"
//     },
//     {
//       "language": "Macedonian",
//       "code": "mk"
//     },
//     {
//       "language": "Malagasy",
//       "code": "mg"
//     },
//     {
//       "language": "Malay",
//       "code": "ms"
//     },
//     {
//       "language": "Malayalam",
//       "code": "ml"
//     },
//     {
//       "language": "Maltese",
//       "code": "mt"
//     },
//     {
//       "language": "Maori",
//       "code": "mi"
//     },
//     {
//       "language": "Marathi",
//       "code": "mr"
//     },
//     {
//       "language": "Mongolian",
//       "code": "mn"
//     },
//     {
//       "language": "Myanmar (Burmese)",
//       "code": "my"
//     },
//     {
//       "language": "Nepali",
//       "code": "ne"
//     },
//     {
//       "language": "Norwegian",
//       "code": "no"
//     },
//     {
//       "language": "Nyanja (Chichewa)",
//       "code": "ny"
//     },
//     {
//       "language": "Pashto",
//       "code": "ps"
//     },
//     {
//       "language": "Persian",
//       "code": "fa"
//     },
//     {
//       "language": "Polish",
//       "code": "pl"
//     },
//     {
//       "language": "Portuguese (Portugal, Brazil)",
//       "code": "pt"
//     },
//     {
//       "language": "Punjabi",
//       "code": "pa"
//     },
//     {
//       "language": "Romanian",
//       "code": "ro"
//     },
//     {
//       "language": "Russian",
//       "code": "ru"
//     },
//     {
//       "language": "Samoan",
//       "code": "sm"
//     },
//     {
//       "language": "Scots Gaelic",
//       "code": "gd"
//     },
//     {
//       "language": "Serbian",
//       "code": "sr"
//     },
//     {
//       "language": "Sesotho",
//       "code": "st"
//     },
//     {
//       "language": "Shona",
//       "code": "sn"
//     },
//     {
//       "language": "Sindhi",
//       "code": "sd"
//     },
//     {
//       "language": "Sinhala (Sinhalese)",
//       "code": "si"
//     },
//     {
//       "language": "Slovak",
//       "code": "sk"
//     },
//     {
//       "language": "Slovenian",
//       "code": "sl"
//     },
//     {
//       "language": "Somali",
//       "code": "so"
//     },
//     {
//       "language": "Spanish",
//       "code": "es"
//     },
//     {
//       "language": "Sundanese",
//       "code": "su"
//     },
//     {
//       "language": "Swahili",
//       "code": "sw"
//     },
//     {
//       "language": "Swedish",
//       "code": "sv"
//     },
//     {
//       "language": "Tagalog (Filipino)",
//       "code": "tl"
//     },
//     {
//       "language": "Tajik",
//       "code": "tg"
//     },
//     {
//       "language": "Tamil",
//       "code": "ta"
//     },
//     {
//       "language": "Telugu",
//       "code": "te"
//     },
//     {
//       "language": "Thai",
//       "code": "th"
//     },
//     {
//       "language": "Turkish",
//       "code": "tr"
//     },
//     {
//       "language": "Ukrainian",
//       "code": "uk"
//     },
//     {
//       "language": "Urdu",
//       "code": "ur"
//     },
//     {
//       "language": "Uzbek",
//       "code": "uz"
//     },
//     {
//       "language": "Vietnamese",
//       "code": "vi"
//     },
//     {
//       "language": "Welsh",
//       "code": "cy"
//     },
//     {
//       "language": "Xhosa",
//       "code": "xh"
//     },
//     {
//       "language": "Yiddish",
//       "code": "yi"
//     },
//     {
//       "language": "Yoruba",
//       "code": "yo"
//     },
//     {
//       "language": "Zulu",
//       "code": "zu"
//     }
//   ]







//   const [inputValue, setInputValue] = useState('');
//   const [welcomeApiMsg, setWelcomeApiMsg] = useState('');
//   const [staticApiVideo, setStaticApiVideo] = useState('');
//   const [welcomeApiVideo, setWelcomeApiVideo] = useState('');
//   const [messages, setMessages] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [videoUrl, setVideoUrl] = useState('');




//   const [audioToText, setAudioToText] = useState('');

//   const [recording, setRecording] = useState(false);
//   const [audioBlob, setAudioBlob] = useState(null);
//   const [timer, setTimer] = useState(0);
//   const [isSoundOn, setIsSoundOn] = useState(true);
//   const [videoLoading, setVideoLoading] = useState(true);
//   const [showChatBot, setShowChatBot] = useState(false);

//   const [recordMessage, setRecordMessage] = useState(false);

//   const [isApiCalled, setIsApiCalled] = useState(false);

//   const videoRef = useRef(null);
//   const mediaRecorderRef = useRef(null);
//   const audioChunksRef = useRef([]);
//   const timerRef = useRef(null);
//   const chatHeadRef = useRef(null);
//   const messagesEndRef = useRef(null); // Ref for the autoscroll
//   const inputRef = useRef(null);

//   const dropdownAvatarRef = useRef(null);
//   const dropdownLanguageRef = useRef(null);

//   const searchInputRef = useRef(null);



//   const [showInitialText, setShowInitialText] = useState(true);
//   const [recentBotIndex, setRecentBotIndex] = useState(null);

//   const [showWelcomeMsg, setShowWelcomeMsg] = useState(false);
//   const [checkAudio, setCheckAudio] = useState(false);

//   const [showWelcomeVideo, setShowWelcomeVideo] = useState(true);

//   const [showLanguages, setShowLanguages] = useState(false);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filteredLanguages, setFilteredLanguages] = useState(languages);



//   const [showAvatar, setShowAvatar] = useState(false);
//   const [selectedLanguage, setSelectedLanguage] = useState('English');
//   const [languageCode, setLanguageCode] = useState('en');




//   const [isRecordingTemp, setIsRecordingTemp] = useState(false);
//   const [audioUrlTemp, setAudioUrlTemp] = useState(null);
//   const [apiResponseTemp, setApiResponseTemp] = useState('');
//   const mediaRecorderRefTemp = useRef(null);
//   const recordedChunksRefTemp = useRef([]);
//   const mediaStreamRefTemp = useRef(null);


//   const audioRef = useRef(null);

//   const audioConstraintsTemp = {
//     audio: {
//       sampleRate: 16000,
//       channelCount: 1,
//       echoCancellation: false,
//       noiseSuppression: false,
//       autoGainControl: false,
//     },
//   };




//   // taking params from parent app and passing in iframe api

//   // const [params, setParams] = useState({
//   //   companyName: '',
//   //   moduleName: '',
//   //   avatarName: ''
//   // });

//   // useEffect(() => {
//   //   const receiveMessage = (event) => {

//   //     const { companyName, moduleName, avatarName } = event.data;
//   //     setParams({ companyName, moduleName, avatarName });

//   //   };

//   //   window.addEventListener('message', receiveMessage, false);

//   //   return () => {
//   //     window.removeEventListener('message', receiveMessage);
//   //   };
//   // }, []);





//   // taking params from iframe url only and passing in iframe api


//   const params = useLocation();

//   const pathSegments = params.pathname.split('/').filter(segment => segment);

//   const companyName = pathSegments[pathSegments.length - 3];
//   const moduleName = pathSegments[pathSegments.length - 2];
//   const avatarName = pathSegments[pathSegments.length - 1];








//   const avatarList = [
//     { name: 'Natasha', image: 'image1.png' },
//     // { name: 'Avatar2', image: 'image2.png' },
//   ];




//   const defaultAvatar = avatarList[0];

//   const [selectedAvatar, setSelectedAvatar] = useState({
//     name: defaultAvatar.name,
//     image: defaultAvatar.image,
//   });

//   useEffect(() => {

//     if (avatarName) {
//       const foundAvatar = avatarList.find(avatar => avatar.name.toLowerCase() === avatarName.toLowerCase());

//       if (foundAvatar) {
//         setSelectedAvatar({
//           name: foundAvatar.name,
//           image: foundAvatar.image,
//         });
//       } else {
//         setSelectedAvatar(defaultAvatar);
//       }
//     } else {
//       setSelectedAvatar(defaultAvatar);
//     }
//   }, [avatarName]);



//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownAvatarRef.current && !dropdownAvatarRef.current.contains(event.target)) {
//         setShowAvatar(false);
//       }
//     };

//     if (showAvatar) {
//       document.addEventListener('mousedown', handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [showAvatar]);





//   const handleAvatarToggle = () => {
//     setShowAvatar(!showAvatar);
//   };

//   const handleLanguageToggle = () => {
//     setShowLanguages(!showLanguages);
//     if (!showLanguages) {
//       setFilteredLanguages(languages);
//       setSearchTerm('');
//     }
//   };

//   const handleLanguageSelect = (language) => {
//     setSelectedLanguage(language.language);
//     setSearchTerm('');
//     setFilteredLanguages(languages);
//     setShowLanguages(false);
//     setLanguageCode(language.code)
//   };



//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value);
//     const filtered = languages.filter((language) =>
//       language.language.toLowerCase().includes(event.target.value.toLowerCase())
//     );
//     setFilteredLanguages(filtered);
//   };


//   useEffect(() => {
//     if (showLanguages) {
//       searchInputRef.current.focus();
//     }
//   }, [showLanguages]);


//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownLanguageRef.current && !dropdownLanguageRef.current.contains(event.target)) {
//         setShowLanguages(false);
//       }
//     };

//     if (showLanguages) {
//       document.addEventListener('mousedown', handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [showLanguages]);

//   const handleKeyDown = (event) => {
//     if (event.key === 'Enter' && filteredLanguages.length > 0) {
//       handleLanguageSelect(filteredLanguages[0]);
//     }
//   };



//   const handleAvatarSelect = (avatar) => {
//     setSelectedAvatar(avatar);
//     setShowAvatar(false);
//   };



//   const handleWelcomeVideoEnd = () => {
//     setShowWelcomeVideo(false);
//   };

//   useEffect(() => {

//     const showTimeout = setTimeout(() => {
//       setShowWelcomeMsg(true);
//     }, 1);

//     const hideTimeout = setTimeout(() => {
//       setShowWelcomeMsg(false);
//     }, 3000);

//     return () => {
//       clearTimeout(showTimeout);
//       clearTimeout(hideTimeout);
//     };
//   }, []);



//   useEffect(() => {
//     const callApiOnPageLoad = async () => {
//       const payload = { user_input: 'hi' };

//       try {
//         const response = await fetch(
//           `https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${selectedAvatar.name.toLowerCase()}/`,
//           {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(payload),
//           }
//         );

//         if (response.ok) {
//           const data = await response.json();
//           setWelcomeApiMsg(data.responses[0])
//           setStaticApiVideo(data.static_video)
//           setWelcomeApiVideo(data.video_path)
//         } else {
//           console.error('API error:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Fetch error:', error);
//       }
//     };

//     callApiOnPageLoad();
//   }, [companyName, moduleName, selectedAvatar]);





//   useEffect(() => {
//     const latestBotIndex = messages
//       .map((item, index) => (item.type === 'bot' ? index : null))
//       .filter(index => index !== null)
//       .pop();

//     if (latestBotIndex !== null) {
//       setRecentBotIndex(latestBotIndex);

//       const timer = setTimeout(() => {
//         setShowInitialText(false);
//       }, 1300);

//       setShowInitialText(true);

//       return () => clearTimeout(timer);
//     }
//   }, [messages]);

//   const handleKeyPress = (event) => {
//     if (event.key === 'Enter') {
//       handleSendMessage();
//     }
//   };


//   const handleSendMessage = async () => {
//     setInputValue(''); // Clear the input field at the start

//     if (inputValue.trim() || audioToText) {
//       setLoading(true);
//       setIsApiCalled(true);

//       const userMessage = {
//         message: audioToText || inputValue.trim() || '',
//         type: 'user',
//         audioUrl: audioToText,
//         isActive: true,
//       };

//       setMessages((prevMessages) => [...prevMessages, userMessage]);

//       const payload = {
//         user_input: audioToText ? audioToText : inputValue.trim() || '',
//         lang: languageCode,
//         companyName: companyName,
//         moduleName: moduleName,
//         avatarName: selectedAvatar.name,
//       };

//       if (messagesEndRef.current) {
//         messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//       }

//       try {
//         const response = await fetch(`https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${selectedAvatar.name.toLowerCase()}/`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(payload),
//         });

//         const data = await response.json();

//         setAudioToText('');
//         setAudioBlob(null);
//         setTimer(0);

//         handleResponse(data); // Call handleResponse to play audio and video

//         if (messagesEndRef.current) {
//           messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//         }

//       } catch (error) {
//         console.error('Error:', error);
//       } finally {
//         setLoading(false);
//       }
//     }
//   };





//   // const handleResponse = (data) => {
//   //   // Check if there are bot responses
//   //   if (data.responses && data.responses.length > 0) {
//   //     const botMessage = data.responses.join(' ');
//   //     setMessages((prevMessages) => [
//   //       ...prevMessages,
//   //       {
//   //         message: botMessage,
//   //         type: 'bot',
//   //       },
//   //     ]);
//   //   } else {
//   //     setMessages((prevMessages) => [
//   //       ...prevMessages,
//   //       {
//   //         message: "Could you please repeat?",
//   //         type: 'bot',
//   //       },
//   //     ]);
//   //   }

//   //   // Check if there is a video path in the response
//   //   if (data.video_path) {
//   //     const fullVideoUrl = data.video_path;
//   //     setVideoUrl(fullVideoUrl);

//   //     // If there is audio data in base64 format, handle the audio
//   //     if (data.audio_data) {
//   //       // Convert the base64 audio data to a Blob
//   //       const audioBlob = base64ToBlob(data.audio_data, 'audio/mp3'); // Assuming audio is in mp3 format
//   //       const audioUrl = URL.createObjectURL(audioBlob); // Create a URL for the audio Blob





//   //       // Create audio and video elements
//   //       const videoElement = document.createElement('video');
//   //       videoElement.src = fullVideoUrl;
//   //       videoElement.controls = true;
//   //       videoElement.autoplay = true;
//   //       videoElement.muted = true; // Mute the video to avoid overlapping sound

//   //       const audioElement = document.createElement('audio');
//   //       audioElement.src = audioUrl;
//   //       audioElement.autoplay = true;


//   //       // const audioElement = new Audio(audioUrl);
//   //     audioRef.current = audioElement; 

//   //       // Sync the audio and video playback
//   //       videoElement.addEventListener('play', () => {
//   //         audioElement.play();
//   //       });

//   //       videoElement.addEventListener('pause', () => {
//   //         audioElement.pause();
//   //       });

//   //       // Append the video element to a container (assuming there is a container for the video)
//   //       const videoContainer = document.getElementById('video-container');
//   //       videoContainer.innerHTML = ''; // Clear any previous video
//   //       videoContainer.appendChild(videoElement);
//   //     } else {
//   //       console.log('No audio data received.');
//   //     }
//   //   } else {
//   //     setVideoUrl(''); // Clear the video if no path is provided
//   //   }
//   // };

//   // Utility function to convert base64 to Blob






//   const handleResponse = (data) => {

//     if (data.responses && data.responses.length > 0) {
//       const botMessage = data.responses.join(' ');
//       setMessages((prevMessages) => [
//         ...prevMessages,
//         {
//           message: botMessage,
//           type: 'bot',
//         },
//       ]);
//     } else {
//       setMessages((prevMessages) => [
//         ...prevMessages,
//         {
//           message: "Could you please repeat?",
//           type: 'bot',
//         },
//       ]);
//     }


//     if (data.video_path) {
//       const fullVideoUrl = data.video_path;
//       setVideoUrl(fullVideoUrl);




//       if (data.audio_data) {

//         if (audioRef.current) {
//           audioRef.current.pause(); 
//           audioRef.current.currentTime = 0; 
//           audioRef.current.src = ''; 
//         }


//         const audioBlob = base64ToBlob(data.audio_data, 'audio/mp3'); 
//         const audioUrl = URL.createObjectURL(audioBlob); 


//         const audioElement = new Audio(audioUrl);
//         audioElement.autoplay = true;


//         audioRef.current = audioElement;


//         audioElement.play();


//         if (videoRef.current) {

//           videoRef.current.muted = true;
//           videoRef.current.src = fullVideoUrl; 
//           videoRef.current.autoplay = true;
//           videoRef.current.playsInline = true; 


//           videoRef.current.removeEventListener('play', handleVideoPlay);
//           videoRef.current.removeEventListener('pause', handleVideoPause);
//           videoRef.current.removeEventListener('ended', handleVideoEnd);


//           const handleVideoPlay = () => {

//             audioElement.play();
//           };


//           const handleVideoPause = () => {
//             audioElement.pause(); 
//           };


//           const handleVideoEnd = () => {
//             audioElement.pause();
//             audioElement.currentTime = 0; 
//             videoRef.current.currentTime = 0; 
//           };

//           videoRef.current.addEventListener('play', handleVideoPlay);
//           videoRef.current.addEventListener('pause', handleVideoPause);
//           videoRef.current.addEventListener('ended', handleVideoEnd);
//         }
//       } else {
//         console.log('No audio data received.');
//       }




//     } else {
//       setVideoUrl(''); // Clear the video if no path is provided
//     }
//   };




//   const base64ToBlob = (base64Data, contentType = '', sliceSize = 512) => {
//     const byteCharacters = atob(base64Data);
//     const byteArrays = [];

//     for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//       const slice = byteCharacters.slice(offset, offset + sliceSize);

//       const byteNumbers = new Array(slice.length);
//       for (let i = 0; i < slice.length; i++) {
//         byteNumbers[i] = slice.charCodeAt(i);
//       }

//       const byteArray = new Uint8Array(byteNumbers);
//       byteArrays.push(byteArray);
//     }

//     return new Blob(byteArrays, { type: contentType });
//   };

//   useEffect(() => {
//     if (videoUrl) {
//       setVideoLoading(true);
//       const video = videoRef.current;
//       if (video) {
//         video.onloadeddata = () => {
//           setVideoLoading(false);

//           video.onended = () => {
//             setVideoUrl('');
//           };
//         };
//       }

//     }
//   }, [videoUrl]);




//   // useEffect(() => {
//   //   if (!loading && !recording) {
//   //     inputRef.current?.focus();
//   //   }
//   //   if (showWelcomeVideo) {
//   //     inputRef.current?.focus();
//   //   }
//   // }, [loading, recording, inputValue, messages]);


//   // recording check 


//   // const startRecording = async () => {
//   //   setRecording(true);
//   //   setRecordMessage(false)
//   //   setTimer(0);
//   //   audioChunksRef.current = [];
//   //   const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//   //   mediaRecorderRef.current = new MediaRecorder(stream);
//   //   mediaRecorderRef.current.ondataavailable = (event) => {
//   //     if (event.data.size > 0) {
//   //       audioChunksRef.current.push(event.data);
//   //     }
//   //   };
//   //   mediaRecorderRef.current.onstop = () => {
//   //     const blob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
//   //     setAudioBlob(blob);
//   //   };
//   //   mediaRecorderRef.current.start();
//   //   timerRef.current = setInterval(() => setTimer((prev) => prev + 1), 1000);
//   // };

//   // const stopRecording = () => {
//   //   setRecordMessage(true)
//   //   setRecording(false);
//   //   if (mediaRecorderRef.current) {
//   //     mediaRecorderRef.current.stop();
//   //   }
//   //   clearInterval(timerRef.current);
//   // };

//   useEffect(() => {
//     if (audioBlob) {
//       sendAudioToApi(audioBlob);
//     }
//   }, [audioBlob]);


//   useEffect(() => {
//     const timer = setTimeout(() => {

//       setRecordMessage(false);
//     }, 3000);

//     return () => clearTimeout(timer);
//   }, [recordMessage]);

//   // const sendAudioToApi = async (blob) => {
//   //   try {
//   //     const base64Audio = await convertBlobToBase64(blob);

//   //     const fileExtension = blob.type.split('/')[1];

//   //     const formData = new FormData();
//   //     formData.append('audio_file', base64Audio.split(',')[1]);
//   //     formData.append('file_extension', fileExtension);


//   //     const response = await fetch('https://speech.pranathiss.com/api/vad-base64-speech-to-text/', {
//   //       method: 'POST',
//   //       body: formData
//   //     });

//   //     const data = await response.json();
//   //     setAudioToText(data.response)
//   //     if (data.response.length > 0) {
//   //       setCheckAudio(false)
//   //     }

//   //     // if (data.response) {
//   //     //   setTimeout(() => {
//   //     //     setRecordMessage(false)
//   //     //     }, 880);
//   //     //   // }, 6200);
//   //     // }


//   //   } catch (error) {
//   //     console.error('Error sending audio:', error);
//   //   }
//   //   finally {
//   //     setCheckAudio(true)
//   //   }
//   // };


//   const convertBlobToBase64 = (blob) => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onloadend = () => resolve(reader.result);
//       reader.onerror = reject;
//       reader.readAsDataURL(blob);
//     });
//   };


//   useEffect(() => {
//     if (videoRef.current) {
//       setVideoLoading(true);
//       videoRef.current.load();
//       videoRef.current.play().finally(() => {
//         setVideoLoading(false);
//       });
//     }
//   }, [videoUrl]);



//   const toggleSound = () => {
//     if (audioRef.current) {
//       audioRef.current.muted = isSoundOn;
//       setIsSoundOn(!isSoundOn);
//     }
//   };




//   // const toggleSound = () => {
//   //   if (videoRef.current && audioRef.current) {
//   //     if (isSoundOn) {
//   //       // Mute both video and external audio
//   //       videoRef.current.muted = true;
//   //       audioRef.current.muted = true; // Mute external audio
//   //     } else {
//   //       // Unmute both video and external audio
//   //       videoRef.current.muted = false;
//   //       audioRef.current.muted = false; // Unmute external audio
//   //     }
//   //     // Toggle sound state
//   //     setIsSoundOn(!isSoundOn);
//   //   }
//   // };











//   const handleChatBotOpen = () => {
//     setShowChatBot(!showChatBot);
//   };

//   useEffect(() => {
//     setShowChatBot(true);
//   }, []);


//   useEffect(() => {
//     handleSendMessage()
//   }, [audioToText])
//   const handleVideoEnd = () => {
//     setVideoUrl(staticApiVideo)
//   }


//   useEffect(() => {
//     if (messagesEndRef.current) {
//       setTimeout(() => {
//         if (messagesEndRef.current) {
//           messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//         }
//       }, 500);
//     }
//   }, [messages, showChatBot]);



//   // audio functionality for android

//   // const sendAudioToApi = async () => {
//   //   if (!isRecordingTemp) {
//   //     try {
//   //       mediaStreamRefTemp.current = await navigator.mediaDevices.getUserMedia(audioConstraintsTemp);
//   //       mediaRecorderRefTemp.current = new MediaRecorder(mediaStreamRefTemp.current, { mimeType: 'audio/webm' });

//   //       mediaRecorderRefTemp.current.ondataavailable = (event) => {
//   //         if (event.data.size > 0) {
//   //           recordedChunksRefTemp.current.push(event.data);
//   //         }
//   //       };

//   //       mediaRecorderRefTemp.current.onstop = async () => {
//   //         const blobTemp = new Blob(recordedChunksRefTemp.current, { type: 'audio/webm' });
//   //         recordedChunksRefTemp.current = [];

//   //         // Convert blob to Base64
//   //         const readerTemp = new FileReader();
//   //         readerTemp.readAsDataURL(blobTemp);
//   //         readerTemp.onloadend = async () => {
//   //           const base64AudioTemp = readerTemp.result.split(',')[1];

//   //           // Display audio for playback
//   //           const audioUrlTemp = URL.createObjectURL(blobTemp);
//   //           setAudioUrlTemp(audioUrlTemp);

//   //           // Prepare the form data
//   //           const formDataTemp = new FormData();
//   //           formDataTemp.append('audio_file', base64AudioTemp);
//   //           formDataTemp.append('file_extension', 'webm');

//   //           // Call the API
//   //           try {
//   //             const responseTemp = await fetch('https://speech.pranathiss.com/api/vad-base64-speech-to-text/', {
//   //               method: 'POST',
//   //               body: formDataTemp,
//   //             });

//   //             const resultTemp = await responseTemp.json();
//   //             console.log('pppppppppppp', resultTemp.response);

//   //             setApiResponseTemp(JSON.stringify(resultTemp, null, 2));
//   //             setAudioToText(resultTemp.response)
//   //             if (resultTemp.response.length > 0) {
//   //               setCheckAudio(false)
//   //             }
//   //           } catch (error) {
//   //             console.error('Error calling the API:', error);
//   //             setApiResponseTemp(`Error: ${error.message}`);
//   //           }
//   //         };
//   //       };

//   //       mediaRecorderRefTemp.current.start();
//   //       setIsRecordingTemp(true);
//   //     } catch (error) {
//   //       console.error('Error accessing microphone: ', error);
//   //     }
//   //   } else {
//   //     // Stop the recording
//   //     mediaRecorderRefTemp.current.stop();
//   //     mediaStreamRefTemp.current.getTracks().forEach((track) => track.stop());
//   //     setIsRecordingTemp(false);
//   //   }
//   // };





//   // audio functionality for ios 

//   const sendAudioToApi = async () => {
//     if (!isRecordingTemp) {
//       try {
//         mediaStreamRefTemp.current = await navigator.mediaDevices.getUserMedia(audioConstraintsTemp);

//         // Detect if the user is on iOS and use 'audio/mp4' else 'audio/webm'
//         const mimeType = isIOS() ? 'audio/mp4' : 'audio/webm';
//         mediaRecorderRefTemp.current = new MediaRecorder(mediaStreamRefTemp.current, { mimeType });

//         mediaRecorderRefTemp.current.ondataavailable = (event) => {
//           if (event.data.size > 0) {
//             recordedChunksRefTemp.current.push(event.data);
//           }
//         };

//         mediaRecorderRefTemp.current.onstop = async () => {
//           const blobTemp = new Blob(recordedChunksRefTemp.current, { type: mimeType });
//           recordedChunksRefTemp.current = [];

//           // Convert blob to Base64
//           const readerTemp = new FileReader();
//           readerTemp.readAsDataURL(blobTemp);
//           readerTemp.onloadend = async () => {
//             const base64AudioTemp = readerTemp.result.split(',')[1];

//             // Display audio for playback
//             const audioUrlTemp = URL.createObjectURL(blobTemp);
//             setAudioUrlTemp(audioUrlTemp);

//             // Prepare the form data
//             const formDataTemp = new FormData();
//             formDataTemp.append('audio_file', base64AudioTemp);
//             formDataTemp.append('file_extension', mimeType === 'audio/mp4' ? 'mp4' : 'webm');

//             // Call the API
//             try {
//               const responseTemp = await fetch('https://speech.pranathiss.com/api/vad-base64-speech-to-text/', {
//                 method: 'POST',
//                 body: formDataTemp,
//               });

//               const resultTemp = await responseTemp.json();
//               console.log('API Response:', resultTemp.response);

//               setApiResponseTemp(JSON.stringify(resultTemp, null, 2));
//               setAudioToText(resultTemp.response);

//               if (resultTemp.response.length > 0) {
//                 setCheckAudio(false);
//               }
//             } catch (error) {
//               console.error('Error calling the API:', error);
//               setApiResponseTemp(`Error: ${error.message}`);
//             }
//           };
//         };

//         mediaRecorderRefTemp.current.start();
//         setIsRecordingTemp(true);
//       } catch (error) {
//         console.error('Error accessing microphone: ', error);
//       }
//     } else {
//       // Stop the recording
//       mediaRecorderRefTemp.current.stop();
//       mediaStreamRefTemp.current.getTracks().forEach((track) => track.stop());
//       setIsRecordingTemp(false);
//     }
//   };

//   // Helper function to check if the user is on iOS
//   const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);











//   return (

//     <>
//       {
//         !showChatBot ?
//           <div className="videochartvalue ">
//             <div className='container'>

//               <div className='headAndOutput'>

//                 <div className='chatHead'>
//                   <div className='headIcontext'>
//                     <div className='group-2'></div>
//                     <div className='iconHeadChat'>
//                       <img src="/img/chatAvatarIcon3.png" alt="Avatar" />
//                       <div className='headTextClass'>
//                         <div className="text-wrapper miniHead">Chat with</div>
//                         <div className="text-wrapper-2">AI Assistant</div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className='headMinClose'>

//                     <div className='languagesDivBoth'>
//                       <div className='languagesDiv' onClick={handleAvatarToggle} ref={dropdownAvatarRef} >
//                         <div className='avatarNameImgClass'>
//                           {selectedAvatar.name}

//                         </div>
//                         <span className={`arrow ${showAvatar ? 'open' : ''}`}>
//                           <img src={arrowDown} alt="" className='downArrowClass' />
//                         </span>
//                         {showAvatar && (
//                           <ul className='languageDropdown'>
//                             {avatarList
//                               .filter(avatar => avatar !== selectedAvatar)
//                               .map((avatar, index) => (
//                                 <li key={index} onClick={() => handleAvatarSelect(avatar)} className='avatarNameImgClass'>

//                                   {avatar.name}
//                                 </li>
//                               ))}
//                           </ul>
//                         )}
//                       </div>


//                       <div ref={dropdownLanguageRef} className='languagesDiv' onClick={handleLanguageToggle}>

//                         {selectedLanguage}

//                         <span className={`arrow ${showLanguages ? 'open' : ''}`}>
//                           <img src={arrowDown} alt="" className='downArrowClass' />
//                         </span>

//                         {showLanguages && (
//                           <ul className='languageDropdown'>

//                             <input
//                               ref={searchInputRef}
//                               type="text"
//                               value={searchTerm}
//                               onChange={handleSearchChange}
//                               onKeyDown={handleKeyDown}
//                               placeholder="Search"
//                               className='searchLanguageInput'
//                             />
//                           </ul>
//                         )}
//                         {showLanguages && (
//                           <ul className='languageDropdown languageSearchDropdown'>

//                             {filteredLanguages.map((language, index) => (
//                               <li key={index} onClick={() => handleLanguageSelect(language)} className='languageOption'>
//                                 <p>

//                                   {language.language}
//                                 </p>
//                               </li>
//                             ))}
//                           </ul>
//                         )}
//                       </div>




//                     </div>

//                     <div className=' speakerIconButton' >
//                       {isSoundOn ? (
//                         <img src={chatSoundIconWhite} alt="Speaker" onClick={toggleSound} />
//                       ) : (
//                         <img src={chatSpeakerMuteIcon} alt="Speaker" onClick={toggleSound} />
//                       )}
//                       {/* <img src="/img/minimizeChatWhite.png" alt="Line" onClick={handleChatBotOpen} /> */}
//                       <img src="/img/minimizeIcon/minimize1.png" alt="Line" onClick={handleChatBotOpen} />
//                     </div>


//                   </div>
//                 </div>



//                 <div id="output-container">
//                   <div className="messages-container">
//                     {
//                       showWelcomeMsg ?


//                         <div className='message-item receiver'>
//                           <div className='senderIconText'>

//                             <div className='message'>
//                               <div className="chat-bubble">
//                                 <div className="typing">
//                                   <div className="dot"></div>
//                                   <div className="dot"></div>
//                                   <div className="dot"></div>
//                                 </div>
//                               </div>
//                             </div>
//                             <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />
//                           </div>
//                         </div>

//                         :
//                         <div className='receiverIconText'>
//                           <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />
//                           <div className="success-status">
//                             {welcomeApiMsg}
//                           </div>
//                         </div>
//                     }



//                     {messages.map((item, index) => (
//                       <div key={index} className={`message-item ${item.type === 'user' ? 'sender' : 'receiver'}`}>
//                         <div className='senderIconText'>
//                           <div className="message">
//                             {
//                               item.audioUrl ?
//                                 recordMessage && index === recentBotIndex ? (
//                                   <div className="chat-bubble-audio">
//                                     <div className="typing">
//                                       <div className="dot"></div>
//                                       <div className="dot"></div>
//                                       <div className="dot"></div>
//                                     </div>
//                                   </div>
//                                 )
//                                   : (
//                                     <p>{item.audioUrl}</p>
//                                   )
//                                 :
//                                 //  (
//                                 //   item.type === 'bot' && index === recentBotIndex && showInitialText ? (
//                                 //     <div className="chat-bubble">
//                                 //       <div className="typing">
//                                 //         <div className="dot"></div>
//                                 //         <div className="dot"></div>
//                                 //         <div className="dot"></div>
//                                 //       </div>
//                                 //     </div>
//                                 //   ) : (
//                                 //     <p>{item.message}</p>
//                                 //   )
//                                 // )
//                                 <p>{item.message}</p>

//                             }
//                           </div>
//                           {item.type === 'user' && <img src="/img/chatSenderIcon.png" alt="Sender Avatar" />}
//                           {item.type === 'bot' && <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />}
//                         </div>
//                         <div ref={messagesEndRef} /> {/* Auto-scroll target */}
//                       </div>
//                     ))}

//                     {/* {recordMessage ?
//                     <div className="senderIconText">
//                       <div className="message">
//                         <div className="typing">
//                           <div className="dot"></div>
//                           <div className="dot"></div>
//                           <div className="dot"></div>
//                         </div>
//                       </div>
//                       <img src="/img/chatSenderIcon.png" alt="Sender Avatar" />

//                     </div>
//                     : ""} */}

//                     <div ref={messagesEndRef} />
//                   </div>





//                   <div className="hello-container" >


//                     {showWelcomeVideo && welcomeApiVideo ? (
//                       <video
//                         ref={videoRef}
//                         width="100%"
//                         autoPlay
//                         muted={!isSoundOn}
//                         onEnded={handleWelcomeVideoEnd}
//                         playsInline
//                       >
//                         <source src={welcomeApiVideo} type="video/mp4" />
//                         Your browser does not support the video tag.
//                       </video>
//                     ) : (
//                       <div>
//                         {!isApiCalled || !videoUrl ? (
//                           <video
//                             ref={videoRef}
//                             width="100%"
//                             autoPlay
//                             loop
//                             muted
//                             playsInline
//                           >
//                             <source src={staticApiVideo} type="video/mp4" />
//                             Your browser does not support the video tag.
//                           </video>
//                         ) : (
//                           <video
//                             ref={videoRef}
//                             width="100%"
//                             autoPlay
//                             // muted={!isSoundOn}
//                             onEnded={handleVideoEnd}
//                             muted
//                             playsInline

//                           >
//                             <source
//                               src={videoUrl}
//                               type="video/mp4" />
//                             Your browser does not support the video tag.
//                           </video>
//                         )}

//                       </div>
//                     )}



//                     {/* <video
//                       ref={videoRef}
//                       width="100%"
//                     height="auto"
//                     objectFit="cover" 
//                       autoPlay
//                       muted={showWelcomeVideo && welcomeApiVideo ? !isSoundOn : true} 
//                       loop={!showWelcomeVideo && (!isApiCalled || !videoUrl)} 
//                       onEnded={showWelcomeVideo && welcomeApiVideo ? handleWelcomeVideoEnd : handleVideoEnd} 
//                       playsInline
//                     >
//                       <source
//                         src={
//                           showWelcomeVideo && welcomeApiVideo
//                             ? welcomeApiVideo 
//                             : !isApiCalled || !videoUrl
//                               ? staticApiVideo 
//                               : videoUrl 
//                         }
//                         type="video/mp4"
//                       />
//                       Your browser does not support the video tag.
//                     </video> */}





//                   </div>


//                 </div>

//               </div>

//               <div className='outputHelpfulClass'>

//                 <div className="input-container">
//                   <input
//                     type="text"
//                     value={inputValue}
//                     onChange={(e) => setInputValue(e.target.value)}
//                     onKeyPress={handleKeyPress}
//                     placeholder="Enter your message"
//                     disabled={loading || recording}
//                     ref={inputRef}
//                   />

//                   {/* {recording ? ( */}
//                   {isRecordingTemp ? (
//                     <div className="recording-indicator">
//                       <div onClick={sendAudioToApi} disabled={loading} className='iconButton'>
//                         <img src="/img/chatMicSpeakIcon.png" alt="Mic" />
//                       </div>
//                     </div>
//                   ) : (
//                     <div className='iconButton' onClick={sendAudioToApi} disabled={loading}>
//                       <img src="/img/chatMicIcon.png" alt="Mic" />
//                     </div>
//                   )}

//                   <div className='iconButton' onClick={handleSendMessage} disabled={loading || recording}>
//                     <img src="/img/chatSendIcon.png" alt="Send" />
//                   </div>
//                 </div>
//               </div>
//             </div>

//           </div>
//           :
//           <div className='chatBoxImageClass' >
//             <img src={chatBoxImage} alt="" onClick={handleChatBotOpen} />

//           </div>
//       }

//     </>

//   );
// };

// export default MessageInput;




















// fixed audio and video merge and iphone compatibility final wala


import React, { useState, useRef, useEffect } from 'react';
import Picker from 'emoji-picker-react';
import './frameNew.css';


import chatBoxImage from '../../../static/img/chatBoxImageCrop1.png'
import arrowDown from '../../../static/img/arrowDownConvert.png'

import chatSoundIconWhite from '../../../static/img/chatSoundSpeaker.png'
import chatSpeakerMuteIcon from '../../../static/img/chatMuteSpeaker.png'
import { useFetcher, useLocation, useParams } from 'react-router-dom';



const MessageInput = () => {


  // const avatarList = [
  //   { name: 'Shweta', image: '/img/avatar/woman.png' },
  //   { name: 'Jensey', image: '/img/avatar/avatarAman.svg' },
  //   { name: 'Aman', image: '/img/avatar/man.png' },
  //   { name: 'Salman', image: '/img/avatar/avatarAman.svg' },
  //   { name: 'Alex', image: '/img/avatar/man.png' },
  //   { name: 'Dharmesh', image: '/img/avatar/woman.png' },
  // ];



  const languages = [
    {
      "language": "Afrikaans",
      "code": "af"
    },
    {
      "language": "Albanian",
      "code": "sq"
    },
    {
      "language": "Amharic",
      "code": "am"
    },
    {
      "language": "Arabic",
      "code": "ar"
    },
    {
      "language": "Armenian",
      "code": "hy"
    },
    {
      "language": "Azerbaijani",
      "code": "az"
    },
    {
      "language": "Basque",
      "code": "eu"
    },
    {
      "language": "Belarusian",
      "code": "be"
    },
    {
      "language": "Bengali",
      "code": "bn"
    },
    {
      "language": "Bosnian",
      "code": "bs"
    },
    {
      "language": "Bulgarian",
      "code": "bg"
    },
    {
      "language": "Catalan",
      "code": "ca"
    },
    {
      "language": "Cebuano",
      "code": "ceb"
    },
    {
      "language": "Chinese (Simplified)",
      "code": "zh-CN"
    },
    {
      "language": "Chinese (Traditional)",
      "code": "zh-TW"
    },
    {
      "language": "Corsican",
      "code": "co"
    },
    {
      "language": "Croatian",
      "code": "hr"
    },
    {
      "language": "Czech",
      "code": "cs"
    },
    {
      "language": "Danish",
      "code": "da"
    },
    {
      "language": "Dutch",
      "code": "nl"
    },
    {
      "language": "English",
      "code": "en"
    },
    {
      "language": "Esperanto",
      "code": "eo"
    },
    {
      "language": "Estonian",
      "code": "et"
    },
    {
      "language": "Finnish",
      "code": "fi"
    },
    {
      "language": "French",
      "code": "fr"
    },
    {
      "language": "Frisian",
      "code": "fy"
    },
    {
      "language": "Galician",
      "code": "gl"
    },
    {
      "language": "Georgian",
      "code": "ka"
    },
    {
      "language": "German",
      "code": "de"
    },
    {
      "language": "Greek",
      "code": "el"
    },
    {
      "language": "Gujarati",
      "code": "gu"
    },
    {
      "language": "Haitian Creole",
      "code": "ht"
    },
    {
      "language": "Hausa",
      "code": "ha"
    },
    {
      "language": "Hawaiian",
      "code": "haw"
    },
    {
      "language": "Hebrew",
      "code": "iw"
    },
    {
      "language": "Hindi",
      "code": "hi"
    },
    {
      "language": "Hmong",
      "code": "hmn"
    },
    {
      "language": "Hungarian",
      "code": "hu"
    },
    {
      "language": "Icelandic",
      "code": "is"
    },
    {
      "language": "Igbo",
      "code": "ig"
    },
    {
      "language": "Indonesian",
      "code": "id"
    },
    {
      "language": "Irish",
      "code": "ga"
    },
    {
      "language": "Italian",
      "code": "it"
    },
    {
      "language": "Japanese",
      "code": "ja"
    },
    {
      "language": "Javanese",
      "code": "jw"
    },
    {
      "language": "Kannada",
      "code": "kn"
    },
    {
      "language": "Kazakh",
      "code": "kk"
    },
    {
      "language": "Khmer",
      "code": "km"
    },
    {
      "language": "Korean",
      "code": "ko"
    },
    {
      "language": "Kurdish",
      "code": "ku"
    },
    {
      "language": "Kyrgyz",
      "code": "ky"
    },
    {
      "language": "Lao",
      "code": "lo"
    },
    {
      "language": "Latin",
      "code": "la"
    },
    {
      "language": "Latvian",
      "code": "lv"
    },
    {
      "language": "Lithuanian",
      "code": "lt"
    },
    {
      "language": "Luxembourgish",
      "code": "lb"
    },
    {
      "language": "Macedonian",
      "code": "mk"
    },
    {
      "language": "Malagasy",
      "code": "mg"
    },
    {
      "language": "Malay",
      "code": "ms"
    },
    {
      "language": "Malayalam",
      "code": "ml"
    },
    {
      "language": "Maltese",
      "code": "mt"
    },
    {
      "language": "Maori",
      "code": "mi"
    },
    {
      "language": "Marathi",
      "code": "mr"
    },
    {
      "language": "Mongolian",
      "code": "mn"
    },
    {
      "language": "Myanmar (Burmese)",
      "code": "my"
    },
    {
      "language": "Nepali",
      "code": "ne"
    },
    {
      "language": "Norwegian",
      "code": "no"
    },
    {
      "language": "Nyanja (Chichewa)",
      "code": "ny"
    },
    {
      "language": "Pashto",
      "code": "ps"
    },
    {
      "language": "Persian",
      "code": "fa"
    },
    {
      "language": "Polish",
      "code": "pl"
    },
    {
      "language": "Portuguese (Portugal, Brazil)",
      "code": "pt"
    },
    {
      "language": "Punjabi",
      "code": "pa"
    },
    {
      "language": "Romanian",
      "code": "ro"
    },
    {
      "language": "Russian",
      "code": "ru"
    },
    {
      "language": "Samoan",
      "code": "sm"
    },
    {
      "language": "Scots Gaelic",
      "code": "gd"
    },
    {
      "language": "Serbian",
      "code": "sr"
    },
    {
      "language": "Sesotho",
      "code": "st"
    },
    {
      "language": "Shona",
      "code": "sn"
    },
    {
      "language": "Sindhi",
      "code": "sd"
    },
    {
      "language": "Sinhala (Sinhalese)",
      "code": "si"
    },
    {
      "language": "Slovak",
      "code": "sk"
    },
    {
      "language": "Slovenian",
      "code": "sl"
    },
    {
      "language": "Somali",
      "code": "so"
    },
    {
      "language": "Spanish",
      "code": "es"
    },
    {
      "language": "Sundanese",
      "code": "su"
    },
    {
      "language": "Swahili",
      "code": "sw"
    },
    {
      "language": "Swedish",
      "code": "sv"
    },
    {
      "language": "Tagalog (Filipino)",
      "code": "tl"
    },
    {
      "language": "Tajik",
      "code": "tg"
    },
    {
      "language": "Tamil",
      "code": "ta"
    },
    {
      "language": "Telugu",
      "code": "te"
    },
    {
      "language": "Thai",
      "code": "th"
    },
    {
      "language": "Turkish",
      "code": "tr"
    },
    {
      "language": "Ukrainian",
      "code": "uk"
    },
    {
      "language": "Urdu",
      "code": "ur"
    },
    {
      "language": "Uzbek",
      "code": "uz"
    },
    {
      "language": "Vietnamese",
      "code": "vi"
    },
    {
      "language": "Welsh",
      "code": "cy"
    },
    {
      "language": "Xhosa",
      "code": "xh"
    },
    {
      "language": "Yiddish",
      "code": "yi"
    },
    {
      "language": "Yoruba",
      "code": "yo"
    },
    {
      "language": "Zulu",
      "code": "zu"
    }
  ]







  const [inputValue, setInputValue] = useState('');
  const [welcomeApiMsg, setWelcomeApiMsg] = useState('');
  const [staticApiVideo, setStaticApiVideo] = useState('');
  const [welcomeApiVideo, setWelcomeApiVideo] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');




  const [audioToText, setAudioToText] = useState('');

  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [timer, setTimer] = useState(0);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [videoLoading, setVideoLoading] = useState(true);
  const [showChatBot, setShowChatBot] = useState(false);

  const [recordMessage, setRecordMessage] = useState(false);

  const [isApiCalled, setIsApiCalled] = useState(false);

  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  const chatHeadRef = useRef(null);
  const messagesEndRef = useRef(null); // Ref for the autoscroll
  const inputRef = useRef(null);

  const dropdownAvatarRef = useRef(null);
  const dropdownLanguageRef = useRef(null);

  const searchInputRef = useRef(null);



  const [showInitialText, setShowInitialText] = useState(true);
  const [recentBotIndex, setRecentBotIndex] = useState(null);

  const [showWelcomeMsg, setShowWelcomeMsg] = useState(false);
  const [checkAudio, setCheckAudio] = useState(false);

  const [showWelcomeVideo, setShowWelcomeVideo] = useState(true);

  const [showLanguages, setShowLanguages] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLanguages, setFilteredLanguages] = useState(languages);



  const [showAvatar, setShowAvatar] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [languageCode, setLanguageCode] = useState('en');




  const [isRecordingTemp, setIsRecordingTemp] = useState(false);
  const [audioUrlTemp, setAudioUrlTemp] = useState(null);
  const [apiResponseTemp, setApiResponseTemp] = useState('');
  const mediaRecorderRefTemp = useRef(null);
  const recordedChunksRefTemp = useRef([]);
  const mediaStreamRefTemp = useRef(null);


  const audioRef = useRef(null);

  const audioConstraintsTemp = {
    audio: {
      sampleRate: 16000,
      channelCount: 1,
      echoCancellation: false,
      noiseSuppression: false,
      autoGainControl: false,
    },
  };




  // taking params from parent app and passing in iframe api

  // const [params, setParams] = useState({
  //   companyName: '',
  //   moduleName: '',
  //   avatarName: ''
  // });

  // useEffect(() => {
  //   const receiveMessage = (event) => {

  //     const { companyName, moduleName, avatarName } = event.data;
  //     setParams({ companyName, moduleName, avatarName });

  //   };

  //   window.addEventListener('message', receiveMessage, false);

  //   return () => {
  //     window.removeEventListener('message', receiveMessage);
  //   };
  // }, []);





  // taking params from iframe url only and passing in iframe api


  const params = useLocation();

  const pathSegments = params.pathname.split('/').filter(segment => segment);

  const companyName = pathSegments[pathSegments.length - 3];
  const moduleName = pathSegments[pathSegments.length - 2];
  const avatarName = pathSegments[pathSegments.length - 1];








  const avatarList = [
    { name: 'Natasha', image: 'image1.png' },
    // { name: 'Avatar2', image: 'image2.png' },
  ];




  const defaultAvatar = avatarList[0];

  const [selectedAvatar, setSelectedAvatar] = useState({
    name: defaultAvatar.name,
    image: defaultAvatar.image,
  });

  useEffect(() => {

    if (avatarName) {
      const foundAvatar = avatarList.find(avatar => avatar.name.toLowerCase() === avatarName.toLowerCase());

      if (foundAvatar) {
        setSelectedAvatar({
          name: foundAvatar.name,
          image: foundAvatar.image,
        });
      } else {
        setSelectedAvatar(defaultAvatar);
      }
    } else {
      setSelectedAvatar(defaultAvatar);
    }
  }, [avatarName]);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownAvatarRef.current && !dropdownAvatarRef.current.contains(event.target)) {
        setShowAvatar(false);
      }
    };

    if (showAvatar) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showAvatar]);





  const handleAvatarToggle = () => {
    setShowAvatar(!showAvatar);
  };

  const handleLanguageToggle = () => {
    setShowLanguages(!showLanguages);
    if (!showLanguages) {
      setFilteredLanguages(languages);
      setSearchTerm('');
    }
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language.language);
    setSearchTerm('');
    setFilteredLanguages(languages);
    setShowLanguages(false);
    setLanguageCode(language.code)
  };



  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    const filtered = languages.filter((language) =>
      language.language.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredLanguages(filtered);
  };


  useEffect(() => {
    if (showLanguages) {
      searchInputRef.current.focus();
    }
  }, [showLanguages]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownLanguageRef.current && !dropdownLanguageRef.current.contains(event.target)) {
        setShowLanguages(false);
      }
    };

    if (showLanguages) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showLanguages]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && filteredLanguages.length > 0) {
      handleLanguageSelect(filteredLanguages[0]);
    }
  };



  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar);
    setShowAvatar(false);
  };



  const handleWelcomeVideoEnd = () => {
    setShowWelcomeVideo(false);
  };

  useEffect(() => {

    const showTimeout = setTimeout(() => {
      setShowWelcomeMsg(true);
    }, 1);

    const hideTimeout = setTimeout(() => {
      setShowWelcomeMsg(false);
    }, 3000);

    return () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    };
  }, []);



  useEffect(() => {
    const callApiOnPageLoad = async () => {
      const payload = { user_input: 'hi' };

      try {
        const response = await fetch(
          `https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${selectedAvatar.name.toLowerCase()}/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setWelcomeApiMsg(data.responses[0])
          setStaticApiVideo(data.static_video)
          setWelcomeApiVideo(data.video_path)
        } else {
          console.error('API error:', response.statusText);
        }
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };

    callApiOnPageLoad();
  }, [companyName, moduleName, selectedAvatar]);





  useEffect(() => {
    const latestBotIndex = messages
      .map((item, index) => (item.type === 'bot' ? index : null))
      .filter(index => index !== null)
      .pop();

    if (latestBotIndex !== null) {
      setRecentBotIndex(latestBotIndex);

      // const timer = setTimeout(() => {
      //   setShowInitialText(false);
      // }, 1300);

      setShowInitialText(true);

      // return () => clearTimeout(timer);
    }
  }, [messages]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };


  const handleSendMessage = async () => {
    setInputValue(''); // Clear the input field at the start

    if (inputValue.trim() || audioToText) {
      setLoading(true);
      setIsApiCalled(true);

      const userMessage = {
        message: audioToText || inputValue.trim() || '',
        type: 'user',
        audioUrl: audioToText,
        isActive: true,
      };

      setMessages((prevMessages) => [...prevMessages, userMessage]);

      const payload = {
        user_input: audioToText ? audioToText : inputValue.trim() || '',
        lang: languageCode,
        companyName: companyName,
        moduleName: moduleName,
        avatarName: selectedAvatar.name,
      };

      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }

      try {
        const response = await fetch(`https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${selectedAvatar.name.toLowerCase()}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        const data = await response.json();

        setAudioToText('');
        setAudioBlob(null);
        setTimer(0);

        handleResponse(data); // Call handleResponse to play audio and video

        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }

      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };





  // const handleResponse = (data) => {
  //   // Check if there are bot responses
  //   if (data.responses && data.responses.length > 0) {
  //     const botMessage = data.responses.join(' ');
  //     setMessages((prevMessages) => [
  //       ...prevMessages,
  //       {
  //         message: botMessage,
  //         type: 'bot',
  //       },
  //     ]);
  //   } else {
  //     setMessages((prevMessages) => [
  //       ...prevMessages,
  //       {
  //         message: "Could you please repeat?",
  //         type: 'bot',
  //       },
  //     ]);
  //   }

  //   // Check if there is a video path in the response
  //   if (data.video_path) {
  //     const fullVideoUrl = data.video_path;
  //     setVideoUrl(fullVideoUrl);

  //     // If there is audio data in base64 format, handle the audio
  //     if (data.audio_data) {
  //       // Convert the base64 audio data to a Blob
  //       const audioBlob = base64ToBlob(data.audio_data, 'audio/mp3'); // Assuming audio is in mp3 format
  //       const audioUrl = URL.createObjectURL(audioBlob); // Create a URL for the audio Blob





  //       // Create audio and video elements
  //       const videoElement = document.createElement('video');
  //       videoElement.src = fullVideoUrl;
  //       videoElement.controls = true;
  //       videoElement.autoplay = true;
  //       videoElement.muted = true; // Mute the video to avoid overlapping sound

  //       const audioElement = document.createElement('audio');
  //       audioElement.src = audioUrl;
  //       audioElement.autoplay = true;


  //       // const audioElement = new Audio(audioUrl);
  //     audioRef.current = audioElement; 

  //       // Sync the audio and video playback
  //       videoElement.addEventListener('play', () => {
  //         audioElement.play();
  //       });

  //       videoElement.addEventListener('pause', () => {
  //         audioElement.pause();
  //       });

  //       // Append the video element to a container (assuming there is a container for the video)
  //       const videoContainer = document.getElementById('video-container');
  //       videoContainer.innerHTML = ''; // Clear any previous video
  //       videoContainer.appendChild(videoElement);
  //     } else {
  //       console.log('No audio data received.');
  //     }
  //   } else {
  //     setVideoUrl(''); // Clear the video if no path is provided
  //   }
  // };

  // Utility function to convert base64 to Blob






  const handleResponse = (data) => {

    if (data.responses && data.responses.length > 0) {
      const botMessage = data.responses.join(' ');
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          message: botMessage,
          type: 'bot',
        },
      ]);
    } else {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          message: "Could you please repeat?",
          type: 'bot',
        },
      ]);
    }


    if (data.video_path) {
      const fullVideoUrl = data.video_path;
      setVideoUrl(fullVideoUrl);




      if (data.audio_data) {

        if (audioRef.current) {
          audioRef.current.pause(); 
          audioRef.current.currentTime = 0; 
          audioRef.current.src = ''; 
        }


        const audioBlob = base64ToBlob(data.audio_data, 'audio/mp3'); 
        const audioUrl = URL.createObjectURL(audioBlob); 


        const audioElement = new Audio(audioUrl);
        audioElement.autoplay = true;


        audioRef.current = audioElement;


        audioElement.play();


        if (videoRef.current) {

          videoRef.current.muted = true;
          videoRef.current.src = fullVideoUrl; 
          videoRef.current.autoplay = true;
          videoRef.current.playsInline = true; 


          videoRef.current.removeEventListener('play', handleVideoPlay);
          videoRef.current.removeEventListener('pause', handleVideoPause);
          videoRef.current.removeEventListener('ended', handleVideoEnd);


          const handleVideoPlay = () => {

            audioElement.play();
          };


          const handleVideoPause = () => {
            audioElement.pause(); 
          };


          const handleVideoEnd = () => {
            audioElement.pause();
            audioElement.currentTime = 0; 
            videoRef.current.currentTime = 0; 
          };

          videoRef.current.addEventListener('play', handleVideoPlay);
          videoRef.current.addEventListener('pause', handleVideoPause);
          videoRef.current.addEventListener('ended', handleVideoEnd);
        }
      } else {
        console.log('No audio data received.');
      }




    } else {
      setVideoUrl(''); // Clear the video if no path is provided
    }
  };




  const base64ToBlob = (base64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  useEffect(() => {
    if (videoUrl) {
      setVideoLoading(true);
      const video = videoRef.current;
      if (video) {
        video.onloadeddata = () => {
          setVideoLoading(false);

          video.onended = () => {
            setVideoUrl('');
          };
        };
      }

    }
  }, [videoUrl]);




  // useEffect(() => {
  //   if (!loading && !recording) {
  //     inputRef.current?.focus();
  //   }
  //   if (showWelcomeVideo) {
  //     inputRef.current?.focus();
  //   }
  // }, [loading, recording, inputValue, messages]);


  // recording check 


  // const startRecording = async () => {
  //   setRecording(true);
  //   setRecordMessage(false)
  //   setTimer(0);
  //   audioChunksRef.current = [];
  //   const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  //   mediaRecorderRef.current = new MediaRecorder(stream);
  //   mediaRecorderRef.current.ondataavailable = (event) => {
  //     if (event.data.size > 0) {
  //       audioChunksRef.current.push(event.data);
  //     }
  //   };
  //   mediaRecorderRef.current.onstop = () => {
  //     const blob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
  //     setAudioBlob(blob);
  //   };
  //   mediaRecorderRef.current.start();
  //   timerRef.current = setInterval(() => setTimer((prev) => prev + 1), 1000);
  // };

  // const stopRecording = () => {
  //   setRecordMessage(true)
  //   setRecording(false);
  //   if (mediaRecorderRef.current) {
  //     mediaRecorderRef.current.stop();
  //   }
  //   clearInterval(timerRef.current);
  // };

  useEffect(() => {
    if (audioBlob) {
      sendAudioToApi(audioBlob);
    }
  }, [audioBlob]);


  useEffect(() => {
    const timer = setTimeout(() => {

      setRecordMessage(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [recordMessage]);

  // const sendAudioToApi = async (blob) => {
  //   try {
  //     const base64Audio = await convertBlobToBase64(blob);

  //     const fileExtension = blob.type.split('/')[1];

  //     const formData = new FormData();
  //     formData.append('audio_file', base64Audio.split(',')[1]);
  //     formData.append('file_extension', fileExtension);


  //     const response = await fetch('https://speech.pranathiss.com/api/vad-base64-speech-to-text/', {
  //       method: 'POST',
  //       body: formData
  //     });

  //     const data = await response.json();
  //     setAudioToText(data.response)
  //     if (data.response.length > 0) {
  //       setCheckAudio(false)
  //     }

  //     // if (data.response) {
  //     //   setTimeout(() => {
  //     //     setRecordMessage(false)
  //     //     }, 880);
  //     //   // }, 6200);
  //     // }


  //   } catch (error) {
  //     console.error('Error sending audio:', error);
  //   }
  //   finally {
  //     setCheckAudio(true)
  //   }
  // };


  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };


  useEffect(() => {
    if (videoRef.current) {
      setVideoLoading(true);
      videoRef.current.load();
      videoRef.current.play().finally(() => {
        setVideoLoading(false);
      });
    }
  }, [videoUrl]);



  const toggleSound = () => {
    if (audioRef.current) {
      audioRef.current.muted = isSoundOn;
      setIsSoundOn(!isSoundOn);
    }
  };




  // const toggleSound = () => {
  //   if (videoRef.current && audioRef.current) {
  //     if (isSoundOn) {
  //       // Mute both video and external audio
  //       videoRef.current.muted = true;
  //       audioRef.current.muted = true; // Mute external audio
  //     } else {
  //       // Unmute both video and external audio
  //       videoRef.current.muted = false;
  //       audioRef.current.muted = false; // Unmute external audio
  //     }
  //     // Toggle sound state
  //     setIsSoundOn(!isSoundOn);
  //   }
  // };











  const handleChatBotOpen = () => {
    setShowChatBot(!showChatBot);
  };

  useEffect(() => {
    setShowChatBot(true);
  }, []);


  useEffect(() => {
    handleSendMessage()
  }, [audioToText])
  const handleVideoEnd = () => {
    setVideoUrl(staticApiVideo)
  }


  useEffect(() => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
    }
  }, [messages, showChatBot]);



  // audio functionality for android

  // const sendAudioToApi = async () => {
  //   if (!isRecordingTemp) {
  //     try {
  //       mediaStreamRefTemp.current = await navigator.mediaDevices.getUserMedia(audioConstraintsTemp);
  //       mediaRecorderRefTemp.current = new MediaRecorder(mediaStreamRefTemp.current, { mimeType: 'audio/webm' });

  //       mediaRecorderRefTemp.current.ondataavailable = (event) => {
  //         if (event.data.size > 0) {
  //           recordedChunksRefTemp.current.push(event.data);
  //         }
  //       };

  //       mediaRecorderRefTemp.current.onstop = async () => {
  //         const blobTemp = new Blob(recordedChunksRefTemp.current, { type: 'audio/webm' });
  //         recordedChunksRefTemp.current = [];

  //         // Convert blob to Base64
  //         const readerTemp = new FileReader();
  //         readerTemp.readAsDataURL(blobTemp);
  //         readerTemp.onloadend = async () => {
  //           const base64AudioTemp = readerTemp.result.split(',')[1];

  //           // Display audio for playback
  //           const audioUrlTemp = URL.createObjectURL(blobTemp);
  //           setAudioUrlTemp(audioUrlTemp);

  //           // Prepare the form data
  //           const formDataTemp = new FormData();
  //           formDataTemp.append('audio_file', base64AudioTemp);
  //           formDataTemp.append('file_extension', 'webm');

  //           // Call the API
  //           try {
  //             const responseTemp = await fetch('https://speech.pranathiss.com/api/vad-base64-speech-to-text/', {
  //               method: 'POST',
  //               body: formDataTemp,
  //             });

  //             const resultTemp = await responseTemp.json();
  //             console.log('pppppppppppp', resultTemp.response);

  //             setApiResponseTemp(JSON.stringify(resultTemp, null, 2));
  //             setAudioToText(resultTemp.response)
  //             if (resultTemp.response.length > 0) {
  //               setCheckAudio(false)
  //             }
  //           } catch (error) {
  //             console.error('Error calling the API:', error);
  //             setApiResponseTemp(`Error: ${error.message}`);
  //           }
  //         };
  //       };

  //       mediaRecorderRefTemp.current.start();
  //       setIsRecordingTemp(true);
  //     } catch (error) {
  //       console.error('Error accessing microphone: ', error);
  //     }
  //   } else {
  //     // Stop the recording
  //     mediaRecorderRefTemp.current.stop();
  //     mediaStreamRefTemp.current.getTracks().forEach((track) => track.stop());
  //     setIsRecordingTemp(false);
  //   }
  // };





  // audio functionality for ios 

  const sendAudioToApi = async () => {
    if (!isRecordingTemp) {
      try {
        mediaStreamRefTemp.current = await navigator.mediaDevices.getUserMedia(audioConstraintsTemp);

        // Detect if the user is on iOS and use 'audio/mp4' else 'audio/webm'
        const mimeType = isIOS() ? 'audio/mp4' : 'audio/webm';
        mediaRecorderRefTemp.current = new MediaRecorder(mediaStreamRefTemp.current, { mimeType });

        mediaRecorderRefTemp.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            recordedChunksRefTemp.current.push(event.data);
          }
        };

        mediaRecorderRefTemp.current.onstop = async () => {
          const blobTemp = new Blob(recordedChunksRefTemp.current, { type: mimeType });
          recordedChunksRefTemp.current = [];

          // Convert blob to Base64
          const readerTemp = new FileReader();
          readerTemp.readAsDataURL(blobTemp);
          readerTemp.onloadend = async () => {
            const base64AudioTemp = readerTemp.result.split(',')[1];

            // Display audio for playback
            const audioUrlTemp = URL.createObjectURL(blobTemp);
            setAudioUrlTemp(audioUrlTemp);

            // Prepare the form data
            const formDataTemp = new FormData();
            formDataTemp.append('audio_file', base64AudioTemp);
            formDataTemp.append('file_extension', mimeType === 'audio/mp4' ? 'mp4' : 'webm');

            // Call the API
            try {
              const responseTemp = await fetch('https://speech.pranathiss.com/api/vad-base64-speech-to-text/', {
                method: 'POST',
                body: formDataTemp,
              });

              const resultTemp = await responseTemp.json();
              console.log('API Response:', resultTemp.response);

              setApiResponseTemp(JSON.stringify(resultTemp, null, 2));
              setAudioToText(resultTemp.response);

              if (resultTemp.response.length > 0) {
                setCheckAudio(false);
              }
            } catch (error) {
              console.error('Error calling the API:', error);
              setApiResponseTemp(`Error: ${error.message}`);
            }
          };
        };

        mediaRecorderRefTemp.current.start();
        setIsRecordingTemp(true);
      } catch (error) {
        console.error('Error accessing microphone: ', error);
      }
    } else {
      // Stop the recording
      mediaRecorderRefTemp.current.stop();
      mediaStreamRefTemp.current.getTracks().forEach((track) => track.stop());
      setIsRecordingTemp(false);
    }
  };

  // Helper function to check if the user is on iOS
  const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);




const handleInputSearch=(e)=>{
  e.stopPropagation()
}






  return (

    <>
      {
        !showChatBot ?
          <div className="videochartvalue ">
            <div className='container'>

              <div className='headAndOutput'>

                <div className='chatHead'>
                  <div className='headIcontext'>
                    <div className='group-2'></div>
                    <div className='iconHeadChat'>
                      <img src="/img/chatAvatarIcon3.png" alt="Avatar" />
                      <div className='headTextClass'>
                        <div className="text-wrapper miniHead">Chat with</div>
                        <div className="text-wrapper-2">AI Assistant</div>
                      </div>
                    </div>
                  </div>
                  <div className='headMinClose'>

                    <div className='languagesDivBoth'>
                      <div className='languagesDiv' onClick={handleAvatarToggle} ref={dropdownAvatarRef} >
                        <div className='avatarNameImgClass'>
                          {selectedAvatar.name}

                        </div>
                        <span className={`arrow ${showAvatar ? 'open' : ''}`}>
                          <img src={arrowDown} alt="" className='downArrowClass' />
                        </span>
                        {showAvatar && (
                          <ul className='languageDropdown'>
                            {avatarList
                              .filter(avatar => avatar !== selectedAvatar)
                              .map((avatar, index) => (
                                <li key={index} onClick={() => handleAvatarSelect(avatar)} className='avatarNameImgClass'>

                                  {avatar.name}
                                </li>
                              ))}
                          </ul>
                        )}
                      </div>


                      <div ref={dropdownLanguageRef} className='languagesDiv' onClick={handleLanguageToggle}>

                        {selectedLanguage}

                        <span className={`arrow ${showLanguages ? 'open' : ''}`}>
                          <img src={arrowDown} alt="" className='downArrowClass' />
                        </span>

                        {showLanguages && (
                          <ul className='languageDropdown'>

                            <input
                              ref={searchInputRef}
                              type="text"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              onKeyDown={handleKeyDown}
                              onClick={handleInputSearch}
                              placeholder="Search"
                              className='searchLanguageInput'
                            />
                          </ul>
                        )}
                        {showLanguages && (
                          <ul className='languageDropdown languageSearchDropdown'>

                            {filteredLanguages.map((language, index) => (
                              <li key={index} onClick={() => handleLanguageSelect(language)} className='languageOption'>
                                <p>

                                  {language.language}
                                </p>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>




                    </div>

                    <div className=' speakerIconButton' >
                      {isSoundOn ? (
                        <img src={chatSoundIconWhite} alt="Speaker" onClick={toggleSound} />
                      ) : (
                        <img src={chatSpeakerMuteIcon} alt="Speaker" onClick={toggleSound} />
                      )}
                      {/* <img src="/img/minimizeChatWhite.png" alt="Line" onClick={handleChatBotOpen} /> */}
                      <img src="/img/minimizeIcon/minimize1.png" alt="Line" onClick={handleChatBotOpen} />
                    </div>


                  </div>
                </div>



                <div id="output-container">
                  <div className="messages-container">
                    {
                      showWelcomeMsg ?


                        <div className='message-item receiver'>
                          <div className='senderIconText'>

                            <div className='message'>
                              <div className="chat-bubble">
                                <div className="typing">
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                </div>
                              </div>
                            </div>
                            <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />
                          </div>
                        </div>

                        :
                        <div className='receiverIconText'>
                          <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />
                          <div className="success-status">
                            {welcomeApiMsg}
                          </div>
                        </div>
                    }



                    {messages.map((item, index) => (
                      <div key={index} className={`message-item ${item.type === 'user' ? 'sender' : 'receiver'}`}>
                        <div className='senderIconText'>
                          <div className="message">
                            {
                              item.audioUrl ?
                                recordMessage && index === recentBotIndex ? (
                                  <div className="chat-bubble-audio">
                                    <div className="typing">
                                      <div className="dot"></div>
                                      <div className="dot"></div>
                                      <div className="dot"></div>
                                    </div>
                                  </div>
                                )
                                  : (
                                    <p>{item.audioUrl}</p>
                                  )
                                :
                                //  (
                                //   item.type === 'bot' && index === recentBotIndex && showInitialText ? (
                                //     <div className="chat-bubble">
                                //       <div className="typing">
                                //         <div className="dot"></div>
                                //         <div className="dot"></div>
                                //         <div className="dot"></div>
                                //       </div>
                                //     </div>
                                //   ) : (
                                //     <p>{item.message}</p>
                                //   )
                                // )
                                <p>{item.message}</p>

                            }
                          </div>
                          {item.type === 'user' && <img src="/img/chatSenderIcon.png" alt="Sender Avatar" />}
                          {item.type === 'bot' && <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />}
                        </div>
                        <div ref={messagesEndRef} /> {/* Auto-scroll target */}
                      </div>
                    ))}

                    {/* {recordMessage ?
                    <div className="senderIconText">
                      <div className="message">
                        <div className="typing">
                          <div className="dot"></div>
                          <div className="dot"></div>
                          <div className="dot"></div>
                        </div>
                      </div>
                      <img src="/img/chatSenderIcon.png" alt="Sender Avatar" />

                    </div>
                    : ""} */}

                    <div ref={messagesEndRef} />
                  </div>





                  <div className="hello-container" >


                    {showWelcomeVideo && welcomeApiVideo ? (
                      <video
                        ref={videoRef}
                        width="100%"
                        autoPlay
                        muted={!isSoundOn}
                        onEnded={handleWelcomeVideoEnd}
                        playsInline
                      >
                        <source src={welcomeApiVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <div>
                        {!isApiCalled || !videoUrl ? (
                          <video
                            ref={videoRef}
                            width="100%"
                            autoPlay
                            loop
                            muted
                            playsInline
                          >
                            <source src={staticApiVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <video
                            ref={videoRef}
                            width="100%"
                            autoPlay
                            // muted={!isSoundOn}
                            onEnded={handleVideoEnd}
                            muted
                            playsInline

                          >
                            <source
                              src={videoUrl}
                              type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        )}

                      </div>
                    )}



                    {/* <video
                      ref={videoRef}
                      width="100%"
                    height="auto"
                    objectFit="cover" 
                      autoPlay
                      muted={showWelcomeVideo && welcomeApiVideo ? !isSoundOn : true} 
                      loop={!showWelcomeVideo && (!isApiCalled || !videoUrl)} 
                      onEnded={showWelcomeVideo && welcomeApiVideo ? handleWelcomeVideoEnd : handleVideoEnd} 
                      playsInline
                    >
                      <source
                        src={
                          showWelcomeVideo && welcomeApiVideo
                            ? welcomeApiVideo 
                            : !isApiCalled || !videoUrl
                              ? staticApiVideo 
                              : videoUrl 
                        }
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video> */}





                  </div>


                </div>

              </div>

              <div className='outputHelpfulClass'>

                <div className="input-container">
                  <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Enter your message"
                    disabled={loading || recording}
                    ref={inputRef}
                  />

                  {/* {recording ? ( */}
                  {isRecordingTemp ? (
                    <div className="recording-indicator">
                      <div onClick={sendAudioToApi} disabled={loading} className='iconButton'>
                        <img src="/img/chatMicSpeakIcon.png" alt="Mic" />
                      </div>
                    </div>
                  ) : (
                    <div className='iconButton' onClick={sendAudioToApi} disabled={loading}>
                      <img src="/img/chatMicIcon.png" alt="Mic" />
                    </div>
                  )}

                  <div className='iconButton' onClick={handleSendMessage} disabled={loading || recording}>
                    <img src="/img/chatSendIcon.png" alt="Send" />
                  </div>
                </div>
              </div>
            </div>

          </div>
          :
          <div className='chatBoxImageClass' >
            <img src={chatBoxImage} alt="" onClick={handleChatBotOpen} />

          </div>
      }

    </>

  );
};

export default MessageInput;

















// this is better than previous , static video is playing after api response 



// import React, { useState, useRef, useEffect } from 'react';
// import Picker from 'emoji-picker-react';
// import './frameNew.css';


// import chatBoxImage from '../../../static/img/chatBoxImageCrop1.png'
// import arrowDown from '../../../static/img/arrowDownConvert.png'

// import chatSoundIconWhite from '../../../static/img/chatSoundSpeaker.png'
// import chatSpeakerMuteIcon from '../../../static/img/chatMuteSpeaker.png'
// import { useFetcher, useLocation, useParams } from 'react-router-dom';



// const MessageInput = () => {


//   // const avatarList = [
//   //   { name: 'Shweta', image: '/img/avatar/woman.png' },
//   //   { name: 'Jensey', image: '/img/avatar/avatarAman.svg' },
//   //   { name: 'Aman', image: '/img/avatar/man.png' },
//   //   { name: 'Salman', image: '/img/avatar/avatarAman.svg' },
//   //   { name: 'Alex', image: '/img/avatar/man.png' },
//   //   { name: 'Dharmesh', image: '/img/avatar/woman.png' },
//   // ];



//   const languages = [
//     {
//       "language": "Afrikaans",
//       "code": "af"
//     },
//     {
//       "language": "Albanian",
//       "code": "sq"
//     },
//     {
//       "language": "Amharic",
//       "code": "am"
//     },
//     {
//       "language": "Arabic",
//       "code": "ar"
//     },
//     {
//       "language": "Armenian",
//       "code": "hy"
//     },
//     {
//       "language": "Azerbaijani",
//       "code": "az"
//     },
//     {
//       "language": "Basque",
//       "code": "eu"
//     },
//     {
//       "language": "Belarusian",
//       "code": "be"
//     },
//     {
//       "language": "Bengali",
//       "code": "bn"
//     },
//     {
//       "language": "Bosnian",
//       "code": "bs"
//     },
//     {
//       "language": "Bulgarian",
//       "code": "bg"
//     },
//     {
//       "language": "Catalan",
//       "code": "ca"
//     },
//     {
//       "language": "Cebuano",
//       "code": "ceb"
//     },
//     {
//       "language": "Chinese (Simplified)",
//       "code": "zh-CN"
//     },
//     {
//       "language": "Chinese (Traditional)",
//       "code": "zh-TW"
//     },
//     {
//       "language": "Corsican",
//       "code": "co"
//     },
//     {
//       "language": "Croatian",
//       "code": "hr"
//     },
//     {
//       "language": "Czech",
//       "code": "cs"
//     },
//     {
//       "language": "Danish",
//       "code": "da"
//     },
//     {
//       "language": "Dutch",
//       "code": "nl"
//     },
//     {
//       "language": "English",
//       "code": "en"
//     },
//     {
//       "language": "Esperanto",
//       "code": "eo"
//     },
//     {
//       "language": "Estonian",
//       "code": "et"
//     },
//     {
//       "language": "Finnish",
//       "code": "fi"
//     },
//     {
//       "language": "French",
//       "code": "fr"
//     },
//     {
//       "language": "Frisian",
//       "code": "fy"
//     },
//     {
//       "language": "Galician",
//       "code": "gl"
//     },
//     {
//       "language": "Georgian",
//       "code": "ka"
//     },
//     {
//       "language": "German",
//       "code": "de"
//     },
//     {
//       "language": "Greek",
//       "code": "el"
//     },
//     {
//       "language": "Gujarati",
//       "code": "gu"
//     },
//     {
//       "language": "Haitian Creole",
//       "code": "ht"
//     },
//     {
//       "language": "Hausa",
//       "code": "ha"
//     },
//     {
//       "language": "Hawaiian",
//       "code": "haw"
//     },
//     {
//       "language": "Hebrew",
//       "code": "iw"
//     },
//     {
//       "language": "Hindi",
//       "code": "hi"
//     },
//     {
//       "language": "Hmong",
//       "code": "hmn"
//     },
//     {
//       "language": "Hungarian",
//       "code": "hu"
//     },
//     {
//       "language": "Icelandic",
//       "code": "is"
//     },
//     {
//       "language": "Igbo",
//       "code": "ig"
//     },
//     {
//       "language": "Indonesian",
//       "code": "id"
//     },
//     {
//       "language": "Irish",
//       "code": "ga"
//     },
//     {
//       "language": "Italian",
//       "code": "it"
//     },
//     {
//       "language": "Japanese",
//       "code": "ja"
//     },
//     {
//       "language": "Javanese",
//       "code": "jw"
//     },
//     {
//       "language": "Kannada",
//       "code": "kn"
//     },
//     {
//       "language": "Kazakh",
//       "code": "kk"
//     },
//     {
//       "language": "Khmer",
//       "code": "km"
//     },
//     {
//       "language": "Korean",
//       "code": "ko"
//     },
//     {
//       "language": "Kurdish",
//       "code": "ku"
//     },
//     {
//       "language": "Kyrgyz",
//       "code": "ky"
//     },
//     {
//       "language": "Lao",
//       "code": "lo"
//     },
//     {
//       "language": "Latin",
//       "code": "la"
//     },
//     {
//       "language": "Latvian",
//       "code": "lv"
//     },
//     {
//       "language": "Lithuanian",
//       "code": "lt"
//     },
//     {
//       "language": "Luxembourgish",
//       "code": "lb"
//     },
//     {
//       "language": "Macedonian",
//       "code": "mk"
//     },
//     {
//       "language": "Malagasy",
//       "code": "mg"
//     },
//     {
//       "language": "Malay",
//       "code": "ms"
//     },
//     {
//       "language": "Malayalam",
//       "code": "ml"
//     },
//     {
//       "language": "Maltese",
//       "code": "mt"
//     },
//     {
//       "language": "Maori",
//       "code": "mi"
//     },
//     {
//       "language": "Marathi",
//       "code": "mr"
//     },
//     {
//       "language": "Mongolian",
//       "code": "mn"
//     },
//     {
//       "language": "Myanmar (Burmese)",
//       "code": "my"
//     },
//     {
//       "language": "Nepali",
//       "code": "ne"
//     },
//     {
//       "language": "Norwegian",
//       "code": "no"
//     },
//     {
//       "language": "Nyanja (Chichewa)",
//       "code": "ny"
//     },
//     {
//       "language": "Pashto",
//       "code": "ps"
//     },
//     {
//       "language": "Persian",
//       "code": "fa"
//     },
//     {
//       "language": "Polish",
//       "code": "pl"
//     },
//     {
//       "language": "Portuguese (Portugal, Brazil)",
//       "code": "pt"
//     },
//     {
//       "language": "Punjabi",
//       "code": "pa"
//     },
//     {
//       "language": "Romanian",
//       "code": "ro"
//     },
//     {
//       "language": "Russian",
//       "code": "ru"
//     },
//     {
//       "language": "Samoan",
//       "code": "sm"
//     },
//     {
//       "language": "Scots Gaelic",
//       "code": "gd"
//     },
//     {
//       "language": "Serbian",
//       "code": "sr"
//     },
//     {
//       "language": "Sesotho",
//       "code": "st"
//     },
//     {
//       "language": "Shona",
//       "code": "sn"
//     },
//     {
//       "language": "Sindhi",
//       "code": "sd"
//     },
//     {
//       "language": "Sinhala (Sinhalese)",
//       "code": "si"
//     },
//     {
//       "language": "Slovak",
//       "code": "sk"
//     },
//     {
//       "language": "Slovenian",
//       "code": "sl"
//     },
//     {
//       "language": "Somali",
//       "code": "so"
//     },
//     {
//       "language": "Spanish",
//       "code": "es"
//     },
//     {
//       "language": "Sundanese",
//       "code": "su"
//     },
//     {
//       "language": "Swahili",
//       "code": "sw"
//     },
//     {
//       "language": "Swedish",
//       "code": "sv"
//     },
//     {
//       "language": "Tagalog (Filipino)",
//       "code": "tl"
//     },
//     {
//       "language": "Tajik",
//       "code": "tg"
//     },
//     {
//       "language": "Tamil",
//       "code": "ta"
//     },
//     {
//       "language": "Telugu",
//       "code": "te"
//     },
//     {
//       "language": "Thai",
//       "code": "th"
//     },
//     {
//       "language": "Turkish",
//       "code": "tr"
//     },
//     {
//       "language": "Ukrainian",
//       "code": "uk"
//     },
//     {
//       "language": "Urdu",
//       "code": "ur"
//     },
//     {
//       "language": "Uzbek",
//       "code": "uz"
//     },
//     {
//       "language": "Vietnamese",
//       "code": "vi"
//     },
//     {
//       "language": "Welsh",
//       "code": "cy"
//     },
//     {
//       "language": "Xhosa",
//       "code": "xh"
//     },
//     {
//       "language": "Yiddish",
//       "code": "yi"
//     },
//     {
//       "language": "Yoruba",
//       "code": "yo"
//     },
//     {
//       "language": "Zulu",
//       "code": "zu"
//     }
//   ]







//   const [inputValue, setInputValue] = useState('');
//   const [welcomeApiMsg, setWelcomeApiMsg] = useState('');
//   const [staticApiVideo, setStaticApiVideo] = useState('');
//   const [welcomeApiVideo, setWelcomeApiVideo] = useState('');
//   const [messages, setMessages] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [videoUrl, setVideoUrl] = useState('');




//   const [audioToText, setAudioToText] = useState('');

//   const [recording, setRecording] = useState(false);
//   const [audioBlob, setAudioBlob] = useState(null);
//   const [timer, setTimer] = useState(0);
//   const [isSoundOn, setIsSoundOn] = useState(true);
//   const [videoLoading, setVideoLoading] = useState(true);
//   const [showChatBot, setShowChatBot] = useState(false);

//   const [recordMessage, setRecordMessage] = useState(false);

//   const [isApiCalled, setIsApiCalled] = useState(false);

//   const videoRef = useRef(null);
//   const mediaRecorderRef = useRef(null);
//   const audioChunksRef = useRef([]);
//   const timerRef = useRef(null);
//   const chatHeadRef = useRef(null);
//   const messagesEndRef = useRef(null); // Ref for the autoscroll
//   const inputRef = useRef(null);

//   const dropdownAvatarRef = useRef(null);
//   const dropdownLanguageRef = useRef(null);

//   const searchInputRef = useRef(null);



//   const [showInitialText, setShowInitialText] = useState(true);
//   const [recentBotIndex, setRecentBotIndex] = useState(null);

//   const [showWelcomeMsg, setShowWelcomeMsg] = useState(false);
//   const [checkAudio, setCheckAudio] = useState(false);

//   const [showWelcomeVideo, setShowWelcomeVideo] = useState(true);

//   const [showLanguages, setShowLanguages] = useState(false);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filteredLanguages, setFilteredLanguages] = useState(languages);



//   const [showAvatar, setShowAvatar] = useState(false);
//   const [selectedLanguage, setSelectedLanguage] = useState('English');
//   const [languageCode, setLanguageCode] = useState('en');

//   const [isAudioFinished, setIsAudioFinished] = useState(false);

//   const [isAudioPlaying, setIsAudioPlaying] = useState(false);


//   const [isRecordingTemp, setIsRecordingTemp] = useState(false);
//   const [audioUrlTemp, setAudioUrlTemp] = useState(null);
//   const [apiResponseTemp, setApiResponseTemp] = useState('');
//   const mediaRecorderRefTemp = useRef(null);
//   const recordedChunksRefTemp = useRef([]);
//   const mediaStreamRefTemp = useRef(null);


//   const audioRef = useRef(null);

//   const audioConstraintsTemp = {
//     audio: {
//       sampleRate: 16000,
//       channelCount: 1,
//       echoCancellation: false,
//       noiseSuppression: false,
//       autoGainControl: false,
//     },
//   };




//   // taking params from parent app and passing in iframe api

//   // const [params, setParams] = useState({
//   //   companyName: '',
//   //   moduleName: '',
//   //   avatarName: ''
//   // });

//   // useEffect(() => {
//   //   const receiveMessage = (event) => {

//   //     const { companyName, moduleName, avatarName } = event.data;
//   //     setParams({ companyName, moduleName, avatarName });

//   //   };

//   //   window.addEventListener('message', receiveMessage, false);

//   //   return () => {
//   //     window.removeEventListener('message', receiveMessage);
//   //   };
//   // }, []);





//   // taking params from iframe url only and passing in iframe api


//   const params = useLocation();

//   const pathSegments = params.pathname.split('/').filter(segment => segment);

//   const companyName = pathSegments[pathSegments.length - 3];
//   const moduleName = pathSegments[pathSegments.length - 2];
//   const avatarName = pathSegments[pathSegments.length - 1];








//   const avatarList = [
//     { name: 'Natasha', image: 'image1.png' },
//     // { name: 'Avatar2', image: 'image2.png' },
//   ];




//   const defaultAvatar = avatarList[0];

//   const [selectedAvatar, setSelectedAvatar] = useState({
//     name: defaultAvatar.name,
//     image: defaultAvatar.image,
//   });

//   useEffect(() => {

//     if (avatarName) {
//       const foundAvatar = avatarList.find(avatar => avatar.name.toLowerCase() === avatarName.toLowerCase());

//       if (foundAvatar) {
//         setSelectedAvatar({
//           name: foundAvatar.name,
//           image: foundAvatar.image,
//         });
//       } else {
//         setSelectedAvatar(defaultAvatar);
//       }
//     } else {
//       setSelectedAvatar(defaultAvatar);
//     }
//   }, [avatarName]);



//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownAvatarRef.current && !dropdownAvatarRef.current.contains(event.target)) {
//         setShowAvatar(false);
//       }
//     };

//     if (showAvatar) {
//       document.addEventListener('mousedown', handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [showAvatar]);





//   const handleAvatarToggle = () => {
//     setShowAvatar(!showAvatar);
//   };

//   const handleLanguageToggle = () => {

//     setShowLanguages(!showLanguages);
//     if (!showLanguages) {
//       setFilteredLanguages(languages);
//       setSearchTerm('');
//     }
//   };

//   const handleLanguageSelect = (language) => {


//     setSelectedLanguage(language.language);
//     setSearchTerm('');
//     setFilteredLanguages(languages);
//     setShowLanguages(false);
//     setLanguageCode(language.code)
//   };



//   const handleSearchChange = (event) => {

//     setSearchTerm(event.target.value);
//     const filtered = languages.filter((language) =>
//       language.language.toLowerCase().includes(event.target.value.toLowerCase())
//     );
//     setFilteredLanguages(filtered);
//   };


//   useEffect(() => {
//     if (showLanguages) {
//       searchInputRef.current.focus();
//     }
//   }, [showLanguages]);


//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownLanguageRef.current && !dropdownLanguageRef.current.contains(event.target)) {
//         setShowLanguages(false);
//       }
//     };



//     if (showLanguages) {
//       document.addEventListener('mousedown', handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [showLanguages]);

//   const handleKeyDown = (event) => {
//     if (event.key === 'Enter' && filteredLanguages.length > 0) {
//       handleLanguageSelect(filteredLanguages[0]);
//     }
//   };



//   const handleAvatarSelect = (avatar) => {
//     setSelectedAvatar(avatar);
//     setShowAvatar(false);
//   };



//   const handleWelcomeVideoEnd = () => {
//     // This function can be customized for the welcome video if needed
//     if (videoRef.current) {
//       videoRef.current.pause(); // Pause the welcome video
//       // You can decide whether to play a static video or do something else
//       videoRef.current.src = staticApiVideo; // Set the source to the static video
//       videoRef.current.muted = false; // Unmute if you want sound
//       videoRef.current.play(); // Play the static video
//     }
//   };

//   useEffect(() => {

//     const showTimeout = setTimeout(() => {
//       setShowWelcomeMsg(true);
//     }, 1);

//     const hideTimeout = setTimeout(() => {
//       setShowWelcomeMsg(false);
//     }, 3000);

//     return () => {
//       clearTimeout(showTimeout);
//       clearTimeout(hideTimeout);
//     };
//   }, []);



//   useEffect(() => {
//     const callApiOnPageLoad = async () => {
//       const payload = { user_input: 'hi' };

//       try {
//         const response = await fetch(
//           `https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${selectedAvatar.name.toLowerCase()}/`,
//           {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(payload),
//           }
//         );

//         if (response.ok) {
//           const data = await response.json();
//           setWelcomeApiMsg(data.responses[0])
//           setStaticApiVideo(data.static_video)
//           setWelcomeApiVideo(data.video_path)
//         } else {
//           console.error('API error:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Fetch error:', error);
//       }
//     };

//     callApiOnPageLoad();
//   }, [companyName, moduleName, selectedAvatar]);





//   useEffect(() => {
//     const latestBotIndex = messages
//       .map((item, index) => (item.type === 'bot' ? index : null))
//       .filter(index => index !== null)
//       .pop();

//     if (latestBotIndex !== null) {
//       setRecentBotIndex(latestBotIndex);

//       // const timer = setTimeout(() => {
//       //   setShowInitialText(false);
//       // }, 1300);

//       setShowInitialText(true);

//       // return () => clearTimeout(timer);
//     }
//   }, [messages]);

//   const handleKeyPress = (event) => {
//     if (event.key === 'Enter') {
//       handleSendMessage();
//     }
//   };


//   const handleSendMessage = async () => {
//     setInputValue(''); // Clear the input field at the start

//     if (inputValue.trim() || audioToText) {
//       setLoading(true);
//       setIsApiCalled(true);

//       const userMessage = {
//         message: audioToText || inputValue.trim() || '',
//         type: 'user',
//         audioUrl: audioToText,
//         isActive: true,
//       };

//       setMessages((prevMessages) => [...prevMessages, userMessage]);

//       const payload = {
//         user_input: audioToText ? audioToText : inputValue.trim() || '',
//         lang: languageCode,
//         companyName: companyName,
//         moduleName: moduleName,
//         avatarName: selectedAvatar.name,
//       };

//       if (messagesEndRef.current) {
//         messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//       }

//       try {
//         const response = await fetch(`https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${selectedAvatar.name.toLowerCase()}/`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(payload),
//         });

//         const data = await response.json();

//         setAudioToText(''); // Reset audio to text input
//         setAudioBlob(null); // Clear audio blob
//         setTimer(0); // Reset timer

//         handleResponse(data); // Call handleResponse to play audio and video

//         if (messagesEndRef.current) {
//           messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//         }

//       } catch (error) {
//         console.error('Error:', error);
//       } finally {
//         setLoading(false); // Reset loading state
//       }
//     }
//   };






//   // const handleResponse = (data) => {
//   //   // Check if there are bot responses
//   //   if (data.responses && data.responses.length > 0) {
//   //     const botMessage = data.responses.join(' ');
//   //     setMessages((prevMessages) => [
//   //       ...prevMessages,
//   //       {
//   //         message: botMessage,
//   //         type: 'bot',
//   //       },
//   //     ]);
//   //   } else {
//   //     setMessages((prevMessages) => [
//   //       ...prevMessages,
//   //       {
//   //         message: "Could you please repeat?",
//   //         type: 'bot',
//   //       },
//   //     ]);
//   //   }

//   //   // Check if there is a video path in the response
//   //   if (data.video_path) {
//   //     const fullVideoUrl = data.video_path;
//   //     setVideoUrl(fullVideoUrl);

//   //     // If there is audio data in base64 format, handle the audio
//   //     if (data.audio_data) {
//   //       // Convert the base64 audio data to a Blob
//   //       const audioBlob = base64ToBlob(data.audio_data, 'audio/mp3'); // Assuming audio is in mp3 format
//   //       const audioUrl = URL.createObjectURL(audioBlob); // Create a URL for the audio Blob





//   //       // Create audio and video elements
//   //       const videoElement = document.createElement('video');
//   //       videoElement.src = fullVideoUrl;
//   //       videoElement.controls = true;
//   //       videoElement.autoplay = true;
//   //       videoElement.muted = true; // Mute the video to avoid overlapping sound

//   //       const audioElement = document.createElement('audio');
//   //       audioElement.src = audioUrl;
//   //       audioElement.autoplay = true;


//   //       // const audioElement = new Audio(audioUrl);
//   //     audioRef.current = audioElement; 

//   //       // Sync the audio and video playback
//   //       videoElement.addEventListener('play', () => {
//   //         audioElement.play();
//   //       });

//   //       videoElement.addEventListener('pause', () => {
//   //         audioElement.pause();
//   //       });

//   //       // Append the video element to a container (assuming there is a container for the video)
//   //       const videoContainer = document.getElementById('video-container');
//   //       videoContainer.innerHTML = ''; // Clear any previous video
//   //       videoContainer.appendChild(videoElement);
//   //     } else {
//   //       console.log('No audio data received.');
//   //     }
//   //   } else {
//   //     setVideoUrl(''); // Clear the video if no path is provided
//   //   }
//   // };

//   // Utility function to convert base64 to Blob





//   const handleResponse = (data) => {
//     if (data.responses && data.responses.length > 0) {
//       const botMessage = data.responses.join(' ');
//       setMessages((prevMessages) => [
//         ...prevMessages,
//         {
//           message: botMessage,
//           type: 'bot',
//         },
//       ]);
//     } else {
//       setMessages((prevMessages) => [
//         ...prevMessages,
//         {
//           message: "Could you please repeat?",
//           type: 'bot',
//         },
//       ]);
//     }

//     if (data.video_path) {
//       const fullVideoUrl = data.video_path;
//       setVideoUrl(fullVideoUrl);

//       if (data.audio_data) {
//         if (audioRef.current) {
//           audioRef.current.pause();
//           audioRef.current.currentTime = 0; // Reset previous audio
//         }


//         const audioBlob = base64ToBlob(data.audio_data, 'audio/mp3');
//         const audioUrl = URL.createObjectURL(audioBlob);

//         const audioElement = new Audio(audioUrl);
//         audioRef.current = audioElement;

//         audioElement.play();

//         if (videoRef.current) {
//           videoRef.current.src = fullVideoUrl;
//           videoRef.current.muted = true; // Always mute video
//           videoRef.current.play();

//           videoRef.current.removeEventListener('ended', handleVideoEnd);

//           const handleVideoEnd = () => {
//             audioElement.pause(); // Pause audio
//             audioElement.currentTime = 0; // Reset audio
//             playStaticVideo(); // Play static video
//           };

//           videoRef.current.addEventListener('ended', handleVideoEnd);
//         }

//         // Ensure that audio plays when the sound is turned on
//         audioElement.addEventListener('ended', () => {
//           if (videoRef.current) {
//             videoRef.current.pause();
//             playStaticVideo();
//           }
//         });
//       } else {
//         console.log('No audio data received.');
//       }
//     } else {
//       setVideoUrl(''); // Clear video if no path provided
//     }
//   };
  
  


//   // Function to play the static video
//   const playStaticVideo = () => {
//     if (videoRef.current) {
//       videoRef.current.src = staticApiVideo; // Set the source to the static video
//       videoRef.current.muted = false; // Unmute if you want sound with the static video
//       videoRef.current.play(); // Start playing the static video
//     }
//   };






//   const base64ToBlob = (base64Data, contentType = '', sliceSize = 512) => {
//     const byteCharacters = atob(base64Data);
//     const byteArrays = [];

//     for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//       const slice = byteCharacters.slice(offset, offset + sliceSize);

//       const byteNumbers = new Array(slice.length);
//       for (let i = 0; i < slice.length; i++) {
//         byteNumbers[i] = slice.charCodeAt(i);
//       }

//       const byteArray = new Uint8Array(byteNumbers);
//       byteArrays.push(byteArray);
//     }

//     return new Blob(byteArrays, { type: contentType });
//   };

//   useEffect(() => {
//     if (videoUrl) {
//       setVideoLoading(true);
//       const video = videoRef.current;
//       if (video) {
//         video.onloadeddata = () => {
//           setVideoLoading(false);

//           video.onended = () => {
//             setVideoUrl('');
//           };
//         };
//       }

//     }
//   }, [videoUrl]);




//   // useEffect(() => {
//   //   if (!loading && !recording) {
//   //     inputRef.current?.focus();
//   //   }
//   //   if (showWelcomeVideo) {
//   //     inputRef.current?.focus();
//   //   }
//   // }, [loading, recording, inputValue, messages]);


//   // recording check 


//   // const startRecording = async () => {
//   //   setRecording(true);
//   //   setRecordMessage(false)
//   //   setTimer(0);
//   //   audioChunksRef.current = [];
//   //   const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//   //   mediaRecorderRef.current = new MediaRecorder(stream);
//   //   mediaRecorderRef.current.ondataavailable = (event) => {
//   //     if (event.data.size > 0) {
//   //       audioChunksRef.current.push(event.data);
//   //     }
//   //   };
//   //   mediaRecorderRef.current.onstop = () => {
//   //     const blob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
//   //     setAudioBlob(blob);
//   //   };
//   //   mediaRecorderRef.current.start();
//   //   timerRef.current = setInterval(() => setTimer((prev) => prev + 1), 1000);
//   // };

//   // const stopRecording = () => {
//   //   setRecordMessage(true)
//   //   setRecording(false);
//   //   if (mediaRecorderRef.current) {
//   //     mediaRecorderRef.current.stop();
//   //   }
//   //   clearInterval(timerRef.current);
//   // };

//   useEffect(() => {
//     if (audioBlob) {
//       sendAudioToApi(audioBlob);
//     }
//   }, [audioBlob]);


//   useEffect(() => {
//     const timer = setTimeout(() => {

//       setRecordMessage(false);
//     }, 3000);

//     return () => clearTimeout(timer);
//   }, [recordMessage]);

//   // const sendAudioToApi = async (blob) => {
//   //   try {
//   //     const base64Audio = await convertBlobToBase64(blob);

//   //     const fileExtension = blob.type.split('/')[1];

//   //     const formData = new FormData();
//   //     formData.append('audio_file', base64Audio.split(',')[1]);
//   //     formData.append('file_extension', fileExtension);


//   //     const response = await fetch('https://speech.pranathiss.com/api/vad-base64-speech-to-text/', {
//   //       method: 'POST',
//   //       body: formData
//   //     });

//   //     const data = await response.json();
//   //     setAudioToText(data.response)
//   //     if (data.response.length > 0) {
//   //       setCheckAudio(false)
//   //     }

//   //     // if (data.response) {
//   //     //   setTimeout(() => {
//   //     //     setRecordMessage(false)
//   //     //     }, 880);
//   //     //   // }, 6200);
//   //     // }


//   //   } catch (error) {
//   //     console.error('Error sending audio:', error);
//   //   }
//   //   finally {
//   //     setCheckAudio(true)
//   //   }
//   // };


//   const convertBlobToBase64 = (blob) => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onloadend = () => resolve(reader.result);
//       reader.onerror = reject;
//       reader.readAsDataURL(blob);
//     });
//   };


//   useEffect(() => {
//     if (videoRef.current) {
//       setVideoLoading(true);
//       videoRef.current.load();
//       videoRef.current.play().finally(() => {
//         setVideoLoading(false);
//       });
//     }
//   }, [videoUrl]);



//   const toggleSound = () => {
//     if (audioRef.current) {
//       audioRef.current.muted = isSoundOn;
//       setIsSoundOn(!isSoundOn);
//     }
//   };


//   // const toggleSound = () => {
//   //   setIsSoundOn((prev) => {
//   //     const newSoundState = !prev; 

//   //     if (newSoundState && audioRef.current) {
//   //       audioRef.current.play(); 
//   //     } 
//   //     else {

//   //       if (audioRef.current) {
//   //         audioRef.current.pause();
//   //         audioRef.current.currentTime = 0; 
//   //       }
//   //     }

//   //     return newSoundState; // Update sound state
//   //   });
//   // };













//   const handleChatBotOpen = () => {
//     setShowChatBot(!showChatBot);
//   };

//   useEffect(() => {
//     setShowChatBot(true);
//   }, []);


//   useEffect(() => {
//     handleSendMessage()
//   }, [audioToText])

//   const handleVideoEnd = () => {
//     // This function is called when the main API video ends
//     if (videoRef.current) {
//       videoRef.current.pause(); // Pause the current video
//       videoRef.current.src = staticApiVideo; // Set the source to the static video
//       videoRef.current.muted = false; // Unmute if you want sound

//       videoRef.current.play(); // Play the static video
//     }
//   };


//   useEffect(() => {
//     if (messagesEndRef.current) {
//       setTimeout(() => {
//         if (messagesEndRef.current) {
//           messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//         }
//       }, 500);
//     }
//   }, [messages, showChatBot]);



//   // audio functionality for android

//   // const sendAudioToApi = async () => {
//   //   if (!isRecordingTemp) {
//   //     try {
//   //       mediaStreamRefTemp.current = await navigator.mediaDevices.getUserMedia(audioConstraintsTemp);
//   //       mediaRecorderRefTemp.current = new MediaRecorder(mediaStreamRefTemp.current, { mimeType: 'audio/webm' });

//   //       mediaRecorderRefTemp.current.ondataavailable = (event) => {
//   //         if (event.data.size > 0) {
//   //           recordedChunksRefTemp.current.push(event.data);
//   //         }
//   //       };

//   //       mediaRecorderRefTemp.current.onstop = async () => {
//   //         const blobTemp = new Blob(recordedChunksRefTemp.current, { type: 'audio/webm' });
//   //         recordedChunksRefTemp.current = [];

//   //         // Convert blob to Base64
//   //         const readerTemp = new FileReader();
//   //         readerTemp.readAsDataURL(blobTemp);
//   //         readerTemp.onloadend = async () => {
//   //           const base64AudioTemp = readerTemp.result.split(',')[1];

//   //           // Display audio for playback
//   //           const audioUrlTemp = URL.createObjectURL(blobTemp);
//   //           setAudioUrlTemp(audioUrlTemp);

//   //           // Prepare the form data
//   //           const formDataTemp = new FormData();
//   //           formDataTemp.append('audio_file', base64AudioTemp);
//   //           formDataTemp.append('file_extension', 'webm');

//   //           // Call the API
//   //           try {
//   //             const responseTemp = await fetch('https://speech.pranathiss.com/api/vad-base64-speech-to-text/', {
//   //               method: 'POST',
//   //               body: formDataTemp,
//   //             });

//   //             const resultTemp = await responseTemp.json();
//   //             console.log('pppppppppppp', resultTemp.response);

//   //             setApiResponseTemp(JSON.stringify(resultTemp, null, 2));
//   //             setAudioToText(resultTemp.response)
//   //             if (resultTemp.response.length > 0) {
//   //               setCheckAudio(false)
//   //             }
//   //           } catch (error) {
//   //             console.error('Error calling the API:', error);
//   //             setApiResponseTemp(`Error: ${error.message}`);
//   //           }
//   //         };
//   //       };

//   //       mediaRecorderRefTemp.current.start();
//   //       setIsRecordingTemp(true);
//   //     } catch (error) {
//   //       console.error('Error accessing microphone: ', error);
//   //     }
//   //   } else {
//   //     // Stop the recording
//   //     mediaRecorderRefTemp.current.stop();
//   //     mediaStreamRefTemp.current.getTracks().forEach((track) => track.stop());
//   //     setIsRecordingTemp(false);
//   //   }
//   // };





//   // audio functionality for ios 

//   const sendAudioToApi = async () => {
//     if (!isRecordingTemp) {
//       try {
//         mediaStreamRefTemp.current = await navigator.mediaDevices.getUserMedia(audioConstraintsTemp);

//         // Detect if the user is on iOS and use 'audio/mp4' else 'audio/webm'
//         const mimeType = isIOS() ? 'audio/mp4' : 'audio/webm';
//         mediaRecorderRefTemp.current = new MediaRecorder(mediaStreamRefTemp.current, { mimeType });

//         mediaRecorderRefTemp.current.ondataavailable = (event) => {
//           if (event.data.size > 0) {
//             recordedChunksRefTemp.current.push(event.data);
//           }
//         };

//         mediaRecorderRefTemp.current.onstop = async () => {
//           const blobTemp = new Blob(recordedChunksRefTemp.current, { type: mimeType });
//           recordedChunksRefTemp.current = [];

//           // Convert blob to Base64
//           const readerTemp = new FileReader();
//           readerTemp.readAsDataURL(blobTemp);
//           readerTemp.onloadend = async () => {
//             const base64AudioTemp = readerTemp.result.split(',')[1];

//             // Display audio for playback
//             const audioUrlTemp = URL.createObjectURL(blobTemp);
//             setAudioUrlTemp(audioUrlTemp);

//             // Prepare the form data
//             const formDataTemp = new FormData();
//             formDataTemp.append('audio_file', base64AudioTemp);
//             formDataTemp.append('file_extension', mimeType === 'audio/mp4' ? 'mp4' : 'webm');

//             // Call the API
//             try {
//               const responseTemp = await fetch('https://speech.pranathiss.com/api/vad-base64-speech-to-text/', {
//                 method: 'POST',
//                 body: formDataTemp,
//               });

//               const resultTemp = await responseTemp.json();
//               console.log('API Response:', resultTemp.response);

//               setApiResponseTemp(JSON.stringify(resultTemp, null, 2));
//               setAudioToText(resultTemp.response);

//               if (resultTemp.response.length > 0) {
//                 setCheckAudio(false);
//               }
//             } catch (error) {
//               console.error('Error calling the API:', error);
//               setApiResponseTemp(`Error: ${error.message}`);
//             }
//           };
//         };

//         mediaRecorderRefTemp.current.start();
//         setIsRecordingTemp(true);
//       } catch (error) {
//         console.error('Error accessing microphone: ', error);
//       }
//     } else {
//       // Stop the recording
//       mediaRecorderRefTemp.current.stop();
//       mediaStreamRefTemp.current.getTracks().forEach((track) => track.stop());
//       setIsRecordingTemp(false);
//     }
//   };

//   // Helper function to check if the user is on iOS
//   const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);



//   const handleInputSearch = (e) => {
//     e.stopPropagation()
//   }


//   return (

//     <>
//       {
//         !showChatBot ?
//           <div className="videochartvalue ">
//             <div className='container'>

//               <div className='headAndOutput'>

//                 <div className='chatHead'>
//                   <div className='headIcontext'>
//                     <div className='group-2'></div>
//                     <div className='iconHeadChat'>
//                       <img src="/img/chatAvatarIcon3.png" alt="Avatar" />
//                       <div className='headTextClass'>
//                         <div className="text-wrapper miniHead">Chat with</div>
//                         <div className="text-wrapper-2">AI Assistant</div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className='headMinClose'>

//                     <div className='languagesDivBoth'>
//                       <div className='languagesDiv' onClick={handleAvatarToggle} ref={dropdownAvatarRef} >
//                         <div className='avatarNameImgClass'>
//                           {selectedAvatar.name}

//                         </div>
//                         <span className={`arrow ${showAvatar ? 'open' : ''}`}>
//                           <img src={arrowDown} alt="" className='downArrowClass' />
//                         </span>
//                         {showAvatar && (
//                           <ul className='languageDropdown'>
//                             {avatarList
//                               .filter(avatar => avatar !== selectedAvatar)
//                               .map((avatar, index) => (
//                                 <li key={index} onClick={() => handleAvatarSelect(avatar)} className='avatarNameImgClass'>

//                                   {avatar.name}
//                                 </li>
//                               ))}
//                           </ul>
//                         )}
//                       </div>


//                       <div ref={dropdownLanguageRef} className='languagesDiv' onClick={handleLanguageToggle}>

//                         {selectedLanguage}

//                         <span className={`arrow ${showLanguages ? 'open' : ''}`}>
//                           <img src={arrowDown} alt="" className='downArrowClass' />
//                         </span>

//                         {showLanguages && (
//                           <ul className='languageDropdown'>
//                             <input
//                               ref={searchInputRef}
//                               type="text"
//                               value={searchTerm}
//                               onChange={handleSearchChange}
//                               onKeyDown={handleKeyDown}
//                               onClick={handleInputSearch}
//                               placeholder="Search"
//                               className='searchLanguageInput'
//                             />
//                           </ul>
//                         )}
//                         {showLanguages && (
//                           <ul className='languageDropdown languageSearchDropdown'>
//                             {filteredLanguages.map((language, index) => (
//                               <li key={index} onClick={() => handleLanguageSelect(language)} className='languageOption'>
//                                 <p>
//                                   {language.language}
//                                 </p>
//                               </li>
//                             ))}
//                           </ul>
//                         )}
//                       </div>



//                     </div>

//                     <div className=' speakerIconButton' >
//                       {/* <button > */}
//                       {isSoundOn ? <img src={chatSoundIconWhite} alt="Sound On" onClick={toggleSound} /> : <img src={chatSpeakerMuteIcon} alt="Sound Off" onClick={toggleSound} />}
//                       {/* </button> */}


//                       {/* <img src="/img/minimizeChatWhite.png" alt="Line" onClick={handleChatBotOpen} /> */}
//                       <img src="/img/minimizeIcon/minimize1.png" alt="Line" onClick={handleChatBotOpen} />
//                     </div>


//                   </div>
//                 </div>



//                 <div id="output-container">
//                   <div className="messages-container">
//                     {
//                       showWelcomeMsg ?

//                         <div className='message-item receiver'>
//                           <div className='senderIconText'>

//                             <div className='message'>
//                               <div className="chat-bubble">
//                                 <div className="typing">
//                                   <div className="dot"></div>
//                                   <div className="dot"></div>
//                                   <div className="dot"></div>
//                                 </div>
//                               </div>
//                             </div>
//                             <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />
//                           </div>
//                         </div>
//                         :
//                         <div className='receiverIconText'>
//                           <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />
//                           <div className="success-status">
//                             {welcomeApiMsg}
//                           </div>
//                         </div>
//                     }



//                     {messages.map((item, index) => (
//                       <div key={index} className={`message-item ${item.type === 'user' ? 'sender' : 'receiver'}`}>
//                         <div className='senderIconText'>
//                           <div className="message">
//                             {
//                               item.audioUrl ?
//                                 recordMessage && index === recentBotIndex ? (
//                                   <div className="chat-bubble-audio">
//                                     <div className="typing">
//                                       <div className="dot"></div>
//                                       <div className="dot"></div>
//                                       <div className="dot"></div>
//                                     </div>
//                                   </div>
//                                 )
//                                   : (
//                                     <p>{item.audioUrl}</p>
//                                   )
//                                 :
//                                 //  (
//                                 //   item.type === 'bot' && index === recentBotIndex && showInitialText ? (
//                                 //     <div className="chat-bubble">
//                                 //       <div className="typing">
//                                 //         <div className="dot"></div>
//                                 //         <div className="dot"></div>
//                                 //         <div className="dot"></div>
//                                 //       </div>
//                                 //     </div>
//                                 //   ) : (
//                                 //     <p>{item.message}</p>
//                                 //   )
//                                 // )
//                                 <p>{item.message}</p>

//                             }
//                           </div>
//                           {item.type === 'user' && <img src="/img/chatSenderIcon.png" alt="Sender Avatar" />}
//                           {item.type === 'bot' && <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />}
//                         </div>
//                         <div ref={messagesEndRef} /> {/* Auto-scroll target */}
//                       </div>
//                     ))}

//                     {/* {recordMessage ?
//                     <div className="senderIconText">
//                       <div className="message">
//                         <div className="typing">
//                           <div className="dot"></div>
//                           <div className="dot"></div>
//                           <div className="dot"></div>
//                         </div>
//                       </div>
//                       <img src="/img/chatSenderIcon.png" alt="Sender Avatar" />

//                     </div>
//                     : ""} */}

//                     <div ref={messagesEndRef} />
//                   </div>


//                   <div className="hello-container" >


//                     {showWelcomeVideo && welcomeApiVideo ? (
//                       <video
//                         ref={videoRef}
//                         width="100%"
//                         autoPlay
//                         onEnded={handleWelcomeVideoEnd}
//                         playsInline
//                       >
//                         <source src={welcomeApiVideo} type="video/mp4" />
//                         Your browser does not support the video tag.
//                       </video>
//                     ) : (
//                       <div>
//                         {!isApiCalled || !videoUrl ? (
//                           <video
//                             ref={videoRef}
//                             width="100%"
//                             autoPlay
//                             loop
//                             muted
//                             playsInline
//                           >
//                             <source src={staticApiVideo} type="video/mp4" />
//                             Your browser does not support the video tag.
//                           </video>
//                         ) : (
//                           <video
//                             ref={videoRef}
//                             width="100%"
//                             autoPlay
//                             onEnded={handleVideoEnd}
//                             muted
//                             playsInline
//                           >
//                             <source
//                               src={videoUrl}
//                               type="video/mp4" />
//                             Your browser does not support the video tag.
//                           </video>
//                         )}

//                       </div>
//                     )}


//                     {/* <video
//                       ref={videoRef}
//                       width="100%"
//                       height="auto"
//                       autoPlay
//                       muted={!isSoundOn} 
//                       loop={!showWelcomeVideo && (!isApiCalled || !videoUrl)}
//                       onEnded={showWelcomeVideo && welcomeApiVideo ? handleWelcomeVideoEnd : handleVideoEnd}
//                       playsInline
//                     >
//                       <source
//                         src={
//                           showWelcomeVideo && welcomeApiVideo
//                             ? welcomeApiVideo
//                             : !isApiCalled || !videoUrl
//                               ? staticApiVideo
//                               : videoUrl
//                         }
//                         type="video/mp4"
//                       />
//                       Your browser does not support the video tag.
//                     </video> */}

//                   </div>


//                 </div>

//               </div>

//               <div className='outputHelpfulClass'>

//                 <div className="input-container">
//                   <input
//                     type="text"
//                     value={inputValue}
//                     onChange={(e) => setInputValue(e.target.value)}
//                     onKeyPress={handleKeyPress}
//                     placeholder="Enter your message"
//                     disabled={loading || recording}
//                     ref={inputRef}
//                   />

//                   {/* {recording ? ( */}
//                   {isRecordingTemp ? (
//                     <div className="recording-indicator">
//                       <div onClick={sendAudioToApi} disabled={loading} className='iconButton'>
//                         <img src="/img/chatMicSpeakIcon.png" alt="Mic" />
//                       </div>
//                     </div>
//                   ) : (
//                     <div className='iconButton' onClick={sendAudioToApi} disabled={loading}>
//                       <img src="/img/chatMicIcon.png" alt="Mic" />
//                     </div>
//                   )}

//                   <div className='iconButton' onClick={handleSendMessage} disabled={loading || recording}>
//                     <img src="/img/chatSendIcon.png" alt="Send" />
//                   </div>
//                 </div>
//               </div>
//             </div>

//           </div>
//           :
//           <div className='chatBoxImageClass' >
//             <img src={chatBoxImage} alt="" onClick={handleChatBotOpen} />

//           </div>
//       }

//     </>

//   );
// };

// export default MessageInput;







