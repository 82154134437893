
// import './botUserApp.css'

// const BotUserApp=()=>{
//     console.log('dddddddd');
//     return(
//         <p>botUserApp</p>
//     )
    
// }
// export default BotUserApp




import React, { useState } from "react";
import './botUserApp.css';

const BotUserApp = () => {
  const [messages, setMessages] = useState([
    { 
      sender: 'bot', 
      text: 'How can I help you? Please select one option:\n1. Talk to customer support\n2. Guide\n3. Contact us' 
    }
  ]);

  const [input, setInput] = useState('');

  // Handle user input and send message
  const handleSend = () => {
    if (!input) return;

    const userMessage = { sender: 'user', text: input };

    // Add user message to chat
    setMessages([...messages, userMessage]);

    // Bot response based on user input
    let botReply = '';
    if (input === '1') {
      botReply = 'You chose to talk to customer support.';
    } else if (input === '2') {
      botReply = 'Here is a guide to help you.';
    } else if (input === '3') {
      botReply = 'You can contact us at contact@company.com.';
    } else {
      botReply = 'Please select a valid option (1, 2, or 3).';
    }

    // Add bot reply to chat
    setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: botReply }
      ]);
    }, 1000); // Adding a delay to mimic bot response time

    setInput(''); // Clear input field after sending
  };

  // Handle Enter key press to send message
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  return (
    <div className="App">
      <div className="chat-window">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${message.sender === 'bot' ? 'bot' : 'user'}`}
          >
            <strong>{message.sender === 'bot' ? 'Bot' : 'User'}:</strong>
            <span className="message-text">{message.text}</span>
          </div>
        ))}
      </div>

      <div className="input-container">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress} // Listen for Enter key press
          placeholder="Type a number (1, 2, or 3)"
        />
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
};

export default BotUserApp;
