// import React from "react";
// import ReactDOMClient from "react-dom/client";
// import { Frame } from "./screens/Frame";
// import FrameNew from "./screens/Frame/frameNew";

// const app = document.getElementById("app");
// const root = ReactDOMClient.createRoot(app);
// // root.render(<Frame />);
// root.render(<FrameNew />);



import React from "react";
import ReactDOMClient from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import the Router
import { Frame } from "./screens/Frame"; // Assuming this is another component
import FrameNew from "./screens/Frame/frameNew"; // Your FrameNew component
import PageNotFound from './screens/Frame/pagenotfound'
import NoEnglishAudio from "./screens/Frame/noAudioEnglish";
import BotUserApp from "./screens/botUserApp/botUserApp";
const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);

// Wrap your FrameNew component in Router
root.render(
  <Router>
      <Routes>
        {/* <Route path="/:companyname/:module/:avatar" exact={true} element={<FrameNew />} /> */}
        <Route path="/:companyname/:module/:avatar" exact={true} element={<NoEnglishAudio />} />
        <Route path="*" element={ <PageNotFound /> } />         
        {/* <Route path="/BotUserApp" element={ <BotUserApp /> } />          */}
      </Routes>
    </Router>
);
